import { PRENOTAZIONI_REQUEST, PRENOTAZIONI_SUCCESS, PRENOTAZIONI_FAILURE } from '../actions';
import { OPEN_EDITPRENOTAZIONE_DIALOG,CLOSE_EDITPRENOTAZIONE_DIALOG } from '../actions';
import { PRENOTAZIONEDET_REQUEST, PRENOTAZIONEDET_SUCCESS, PRENOTAZIONEDET_FAILURE } from '../actions';
import { SAVEPRENOTAZIONE_REQUEST, SAVEPRENOTAZIONE_SUCCESS, SAVEPRENOTAZIONE_FAILURE } from '../actions';
import { DELPRENOTAZIONE_REQUEST, DELPRENOTAZIONE_SUCCESS, DELPRENOTAZIONE_FAILURE } from '../actions';
import { CLOSE_EDITPRENOTAZIONEESITO_DIALOG } from '../actions';
// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.



export default function prenotazioni(state = {
	isFetching: false,
	isAuthenticated: localStorage.getItem('id_token') ? true : false,
    prenotazioni_list:[],
    errorMessage:'',
    editprenotazione_dialog_open:false,
    prenotazioni_esito_dialog:false,
    prenotazione_detail:{
        Persona_Autorizzata:{},
        Shipper:{}
    }
	}, action) {
	switch (action.type) {
		case PRENOTAZIONI_REQUEST:
			return Object.assign({}, state, {
                isFetching: true
			})
		case PRENOTAZIONI_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
                authenticated: action.authenticated || false,
				prenotazioni_list:action.response.data
			})
		case PRENOTAZIONI_FAILURE:
			return Object.assign({}, state, {
                isFetching: false,
                prenotazioni_esito_dialog:true,
				errorMessage: action.message
            })
        case OPEN_EDITPRENOTAZIONE_DIALOG:
            return Object.assign({}, state, {
                prenotazioni_esito_dialog:false,
                editprenotazione_dialog_open:true
            })
        case CLOSE_EDITPRENOTAZIONE_DIALOG:
            return Object.assign({}, state, {
                errorMessage:'',
                prenotazioni_esito_dialog:false,
                prenotazione_detail:{
                    Persona_Autorizzata:{},
                    Shipper:{}
                },
                editprenotazione_dialog_open:false
            })
        case PRENOTAZIONEDET_REQUEST:
			return Object.assign({}, state, {
                prenotazioni_esito_dialog:false,
				isFetching: true
			})
		case PRENOTAZIONEDET_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
                errorMessage: '',
                authenticated: action.authenticated || false,
				prenotazione_detail:action.response.data
			})
		case PRENOTAZIONEDET_FAILURE:
			return Object.assign({}, state, {
                isFetching: false,
                prenotazioni_esito_dialog:true,
				errorMessage: action.message
            })
        case CLOSE_EDITPRENOTAZIONEESITO_DIALOG:
            return Object.assign({}, state, {
                errorMessage:'',
                prenotazione_detail:{
                    Persona_Autorizzata:{},
                    Shipper:{}
                },
                prenotazioni_esito_dialog:false
            })
        case SAVEPRENOTAZIONE_REQUEST:
			return Object.assign({}, state, {
                isFetching: true,
                prenotazioni_esito_dialog:false,
                errorMessage:''
			})
		case SAVEPRENOTAZIONE_SUCCESS:
			return Object.assign({}, state, {
                isFetching: false,
                messageOpen:true,
                prenotazioni_esito_dialog:true,
                errorMessage: '',
                editprenotazione_dialog_open:false
			})
        case SAVEPRENOTAZIONE_FAILURE:
        
			return Object.assign({}, state, {
                isFetching: false,
                messageOpen:true,
                prenotazioni_esito_dialog:true,
                errorMessage: action.error,
                editprenotazione_dialog_open:false
            })
        case DELPRENOTAZIONE_REQUEST:
			return Object.assign({}, state, {
                isFetching: true,
                prenotazioni_esito_dialog:false,
                errorMessage:''
			})
        case DELPRENOTAZIONE_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                messageOpen:true,
                prenotazioni_esito_dialog:true,
                errorMessage: action.error,
                editprenotazione_dialog_open:false
            })
        case DELPRENOTAZIONE_SUCCESS:
            var delElem = function (element) {
                return element.id === action.response;
            }
            var newPrenList = state.prenotazioni_list;
            var idx = newPrenList.findIndex(delElem);
            newPrenList.splice(idx, 1);
            var prenProp = JSON.parse(JSON.stringify(newPrenList))
            console.log(action,state);
            return Object.assign({}, state, {
                prenotazioni_list:prenProp,
                isFetching: false
            })
		default:
			return state
	}
}