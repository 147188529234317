// The middleware to call the API for quotes
import { CALL_API } from '../middleware/api'
import { CAUSALI_REQUEST, CAUSALI_SUCCESS, CAUSALI_FAILURE } from '../actions';

export function getCausali(filter) {
    return {
        [CALL_API]: {
            endpoint: 'pesata/causali',
            authenticated: true,
            types: [CAUSALI_REQUEST, CAUSALI_SUCCESS, CAUSALI_FAILURE]
        }
    }
}