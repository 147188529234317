import { CALL_API } from '../middleware/api'
import { NAVBAR_TOGGLE_SWITCH,NAVBAR_NAVIGATE_TO } from '../actions';
import { USER_INFO_REQUEST, USER_INFO_SUCCESS, USER_INFO_FAILURE } from '../actions';

export function toggleSideNav(mustClose) {
	return{
		type: 'NAVBAR_TOGGLE_SWITCH',
		authenticated: true,
		mustclose:mustClose
	}
}

export function navigateTo(route) {
	return{
		type: 'NAVBAR_NAVIGATE_TO',
		authenticated: true,
		selected:route
	}
}

export function getUserInfo() {
    return {
        [CALL_API]: {
            endpoint: 'user/info',
            authenticated: true,
            types: [USER_INFO_REQUEST, USER_INFO_SUCCESS, USER_INFO_FAILURE]
        }
    }
}

