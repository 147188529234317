import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import ReactMapboxGl, { Map,Layer, Feature,Marker,Popup} from 'react-mapbox-gl';
import mapboxgl from 'mapbox-gl';
import markerUrl from '../img/poiForMap.png'

class CustomMap extends Component{
    constructor(props){
        super(props);
      
    }
    render(){
        const Map = ReactMapboxGl({
            accessToken:
              'pk.eyJ1IjoibGZhenppaW5kdXN0cmlhbCIsImEiOiJja2I4MG52cDgwOXY3MzRubjViMDY3aHBkIn0.qjNhIXSOR-cmQKA-iGis6w',
            
        });
        
        return(
            
            <div>
                <Map
                    style="mapbox://styles/mapbox/satellite-v9"
                    containerStyle={{
                        height: '300px',
                        width: '400px'
                    }}
                    center={{lat:this.props.lng,lng:this.props.lat}}
                    zoom={[12]}
                >
                   
                    <Popup
                        coordinates={[this.props.lat, this.props.lng]}
                        style={{textAlign:"center"}}
                        >
                        <h1>Industrial Software</h1>
                        <h1>Via Frassina 51</h1>
                    </Popup>
                </Map>
                
            </div>
            
        )
    }
}
 export default CustomMap;