// components/Login.js

import React, { Component } from 'react'
import PropTypes from 'prop-types';
import LockIcon from 'material-ui-icons/Lock';
import TextField from 'material-ui/TextField';
import Button from 'material-ui/Button';
import Paper from 'material-ui/Paper';
import styles from '../css/app.css'
import styled from 'styled-components';
import CustomSideNav from './CustomSideNav'
import { connect } from 'react-redux'
import logo from '../img/logo.png'
import purple from 'material-ui/colors/purple';
import { withStyles } from 'material-ui/styles';
import ReactDataGrid from 'react-data-grid';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../css/react_dates_overrides.css';
import { getFatture,stampaFattura }  from '../actions/fattureAction';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import { MenuItem } from 'material-ui/Menu';
import Select from 'material-ui/Select';
import Input, { InputLabel } from 'material-ui/Input';
import { FormControl, FormHelperText } from 'material-ui/Form';
import Tooltip from 'material-ui/Tooltip';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from 'material-ui/Dialog';


import ContentPrintLabel from 'material-ui-icons/Print';

var moment = require('moment');
require('moment/locale/it');
console.log(moment.locale()); // cs

class DateFormatter extends React.Component {
    static propTypes = {
      value: PropTypes.number.isRequired
    };
  
    render() {
      const percentComplete = this.props.value + '%';
      return (
        <div>
            { new moment(this.props.value).format("D/M/YYYY") }
        </div>);
    }
  }

//Columns definition
var columns = [
    {
        key: '',
        name: '',
        width: 15
    },
    {
      key: 'ID',
      name: 'ID',
      width: 75
    },
    {
        key: 'Data',
        name: 'Data',
        formatter : DateFormatter,
        width: 150
      },
    {
      key: 'Numero',
      name: 'Numero',
      width: 100
    },
    {
      key: 'Imponibile',
      name: 'Imponibile',
      sortable : true,
      width: 150,
      resizable : true
    },
    {
        key: 'TotIva',
        name: 'Iva',
        width: 150
    },
    {
        key: 'Totale',
        name: 'Totale',
        width: 150
    },
    {
        key: 'Note',
        name: 'Note',
        width: 400
    },
    
];

const Main = styled.main`
    position: relative;
    overflow: hidden;
    transition: all .15s;
    padding: 0 0px;
    z-index: 1007;
    display:block;
    margin-left: ${props => (props.expanded ? 230 : 55)}px;
`;

const stile = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
    },
    inputLabelFocused: {
        color: purple[500],
    },
    inputInkbar: {
        '&:after': {
            backgroundColor: purple[500],
        },
    },
    textFieldRoot: {
        padding: 0,
        'label + &': {
            marginTop: theme.spacing.unit * 3,
        },
    },
    inputRoot:{
        padding: '8px 8px 10px 8px',
        border: '1px solid #dbdbdb',
        '&:after':{
            height:0
        },
        '&:before':{
            height:0
        }
    },
    buttonRoot:{
        marginRight:20
    },
    textFieldInput: {
        borderRadius: 3,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '9px 12px',
        margin:'10px',
        width: 'calc(100% - 24px)',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: '#989898',
            boxShadow: '0 0 0 0.1rem rgba(101,101,101,.25)',
        },
    },
    textFieldFormLabel: {
        fontSize: 18,
    },
});

class Fatture extends Component {

    constructor(props) {
        super(props);

        this.state = {
			selectedRow:null,
			selectedRows:null,
			rows:null,
            selectedIndexes:[],
            da:moment().subtract(1, 'months'),
            a:moment(),
            causale:'null',
            barcode:''
        };
        
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    componentWillUnmount() {
        this.setState({open: false});
    }

    componentDidMount(){
        this.props.dispatch(getFatture({da:this.state.da.format('YYYY-MM-DD'),a:this.state.a.add(1, 'days').format('YYYY-MM-DD')}));
        
    }

    handleGridSort = (sortColumn, sortDirection) => {
		var comparer = function(a, b) {
	      if(sortDirection === 'ASC'){
	        return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
	      }else if(sortDirection === 'DESC'){
	        return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
	      }
	    }
	    var rows_app = this.state.rows === null ? this.props.fatture_list.slice(0) : this.state.rows;
	    var rows = sortDirection === 'NONE' ? this.props.fatture_list.slice(0) : rows_app.sort(comparer);
	    this.setState({rows : rows,selectedRow:null,selectedRows:null,selectedIndexes:[]});
    };
    
    rowGetter = (i) => {
        return this.state.rows!==null ? this.state.rows[i] : this.props.fatture_list[i];
    };
  
    onRowsSelected = (rows) => {
        /*if(rowNum.length!=0){
            this.setState({selectedRow: this.props.fatture_list[rowNum],selectedRows:rowNum});
        }
        else{
            this.setState({selectedRow: null,selectedRows:null});
        }*/
        var rowNum = rows.map(r => r.rowIdx)[0];
        this.setState({selectedRow: rows[0].row,selectedRows:rowNum,selectedIndexes: rows.map(r => r.rowIdx)});
    };
  
    onRowsDeselected = (rows) => {
        this.setState({selectedRow: null,selectedRows:null,selectedIndexes: []});
    };
  
    onRowClick = (rowIdx, row) => {
        if(rowIdx===this.state.selectedRows){
            this.setState({selectedRow: null,selectedRows:null,selectedIndexes: []});
        }
        else{
            this.setState({selectedRow: row,selectedRows:rowIdx,selectedIndexes: [rowIdx]});
        }

        /* rows[rowIdx] = Object.assign({}, row, {isSelected: !row.isSelected});
        this.setState({rows});*/
    }

   
    updateFattureList=(filter)=>{
        this.props.dispatch(getFatture(filter));
    }
    

    handleStampaFatturaClick = () => {
        this.props.dispatch(stampaFattura(this.state.selectedRow.ID));
    };


    componentDidUpdate = () => {
        setTimeout(() => {
            if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
                var evt = document.createEvent('UIEvents');
                evt.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(evt);
               } else {
                  window.dispatchEvent(new Event('resize'));
           
               }
            //document.getElementsByClassName("react-grid-Canvas")[0].click()
        }, 50);
        
    }

    handleChange = (e,elem,val)=>{
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    
    isButtonDisabled = () => {
		return this.state.selectedRow===null;
    };
    
   
    

    render() {
        const { classes } = this.props;
        const { errorMessage,dispatch,expanded,history,selected,associapesata_dialog_open,associa_error_message } = this.props;

        console.log(this.props);


        return (
            
            <div>
                {
					this.props.isFetching &&
					<div className="fullBG">
						<div className="loader">
							<div></div>
						</div>
					</div>
				}
                <CustomSideNav
                    dispatch={dispatch}
                    errorMessage={errorMessage}
                    history={history}
                    selected={selected}
                    expanded={expanded}
                />
                <Main expanded={expanded}>
                    <div className="header">
                        <div className="header-content clearfix">
                            <div className="page-title">
                                <p>Fatture</p>
                            </div>
                            <div className="site-title">
                                <div>
                                    <p>PESA PUBBLICA</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="filter-container">
                        <div className="filter-content">
                            <p className="picker-label"><span>Da</span></p>
                            <SingleDatePicker
                                date={this.state.da} // momentPropTypes.momentObj or null
                                onDateChange={(date) => {this.setState({ da:date }); this.updateFattureList({da:date.format('YYYY-MM-DD'),a:moment(this.state.a).add(1, 'days').format('YYYY-MM-DD')}); }} // PropTypes.func.isRequired
                                focused={this.state.focusedDa} // PropTypes.bool
                                onFocusChange={({ focused }) => this.setState({ focusedDa:focused })} // PropTypes.func.isRequired
                                isOutsideRange ={() => false }
                            />
                            <p className="picker-label"><span>A</span></p>
                            <SingleDatePicker
                                date={this.state.a} // momentPropTypes.momentObj or null
                                onDateChange={(date) => {this.setState({ a:date }); this.updateFattureList({da:this.state.da.format('YYYY-MM-DD'),a:moment(date).add(1, 'days').format('YYYY-MM-DD')}); }} // PropTypes.func.isRequired
                                focused={this.state.focusedA} // PropTypes.bool
                                onFocusChange={({ focused }) => this.setState({ focusedA:focused })} // PropTypes.func.isRequired
                                isOutsideRange ={() => false }
                            />
                            
                        </div>
                    </div>
                    <div className="main-container grid-container">
                    {false && <div className="action-button">
                        <Tooltip title="Stampa Fattura" placement="bottom">
                            <Button
                                color="primary"
                                variant="fab"
                                classes={{
                                    root: classes.buttonRoot,
                                }}
                                data-for='add'
                                disabled={this.isButtonDisabled()}
                                mini={true}
                                onClick={this.handleStampaFatturaClick}
                            >
                                <ContentPrintLabel />
                            </Button>
                        </Tooltip>
                    
                    </div>
                    }
                    <ReactDataGrid
                            onGridSort={this.handleGridSort}
                            columns={columns}
                            rowGetter={this.rowGetter}
                            rowsCount={this.state.rows!==null ? this.state.rows.length : this.props.fatture_list.length}
                            minHeight={320}
                            onRowUpdated={this.handleRowUpdated}
                            rowSelection={{
                                    showCheckbox: true,
                                    enableShiftSelect: false,
                                    onRowsSelected: this.onRowsSelected,
                                    onRowsDeselected: this.onRowsDeselected,
                                    selectBy: {
                                        indexes: this.state.selectedIndexes
                                    }
                            }}
                            onRowClick={this.onRowClick}
                    />
                
                    </div>
                </Main>
            </div>
        )
    }
}

Fatture.propTypes = {
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    history: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    const { sidemenu,weight,fatture } = state
    
    const {isFetching,fatture_list} = fatture
    const { expanded,selected } = sidemenu
    return {
        isFetching,
        expanded,
        selected,
        fatture_list
    }
}

export default withStyles(stile)(connect(mapStateToProps)(Fatture));

/*
<NavItem eventKey="settings">
<NavIcon>
<i className="icon-address" style={{ fontSize: '1.5em', verticalAlign: 'middle' }} />
</NavIcon>
<NavText style={{ paddingRight: 32 }}>
Gestione Trasferte
</NavText>
<NavItem eventKey="settings/policy">
<NavText>
Policy
</NavText>
</NavItem>
<NavItem eventKey="settings/network">
<NavText>
Network
</NavText>
</NavItem>
</NavItem>
*/