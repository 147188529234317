// components/Login.js

import React, { Component } from 'react'
import PropTypes from 'prop-types';
import LockIcon from 'material-ui-icons/Lock';
import TextField from 'material-ui/TextField';
import Button from 'material-ui/Button';
import Paper from 'material-ui/Paper';
import styles from '../css/app.css'
import logo from '../img/logo.png'
import logoAnt from '../img/logoAnt.jpg'
import styled from 'styled-components';
import { FormControl,FormHelperText,FormControlLabel   } from 'material-ui/Form';
import Input, { InputLabel } from 'material-ui/Input';
import CustomSideNav from './CustomSideNav'
import { connect } from 'react-redux'
import purple from 'material-ui/colors/purple';
import { withStyles } from 'material-ui/styles';
import Grid from 'material-ui/Grid';
import { openPasswordDialog,closePasswordDialog,cambiaPassword,closeEditDialog,salvaUtente }  from '../actions/editUserAction';
import { ristampaTessera }  from '../actions/tesseraAction';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from 'material-ui/Dialog';
import Switch from 'material-ui/Switch';
import ContentPrint from 'material-ui-icons/Print';
import ContentPassword from 'material-ui-icons/VpnKey';

var isEmpty = function(obj){
    return Object.keys(obj).length === 0 && obj.constructor === Object
}

const Main = styled.main`
		position: relative;
		overflow: hidden;
		transition: all .15s;
		padding: 0 0px;
        z-index: 1007;
        display:block;
		margin-left: ${props => (props.expanded ? 230 : 55)}px;
	`;

    const stile = theme => ({
        container: {
          display: 'flex',
          flexWrap: 'wrap',
        },
        formControl: {
          margin: theme.spacing.unit,
          width:250
        },
        inputLabelFocused: {
          color: 'rgb(0, 121, 191)',
        },
        inputInkbar: {
          '&:after': {
            backgroundColor: 'rgb(0, 121, 191)',
          },
        },
        textFieldRoot: {
          padding: 0,
          'label + &': {
            marginTop: theme.spacing.unit * 3,
          },
        },
        textFieldInput: {
          borderRadius: 4,
          backgroundColor: theme.palette.common.white,
          border: '1px solid #ced4da',
          fontSize: 16,
          padding: '10px 12px',
          width: 'calc(100% - 24px)',
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
          },
        },
        textFieldFormLabel: {
          fontSize: 18,
        },
        checked: {
            color: 'rgb(0, 121, 191)',
            '& + $bar': {
              backgroundColor: 'rgb(0, 121, 191)',
            },
          },
          bar: {},
          toggleRoot:{
            padding: '10px 12px'
          },
        button: {
            margin: theme.spacing.unit,
          },
          rightIcon: {
            marginLeft: theme.spacing.unit,
          },
      });

	
class GestioneUtente extends Component {
   
	constructor(props) {
        super(props);
        this.state = {
            user: {
                CAP:"",
                Cellulare:"",
                Cognome:"",
                Email:"",
                Indirizzo:"",
                Localita:"",
                Nome:"",
                PEC:"",
                Paese:"",
                Password:null,
                Piva:"",
                Provincia:"",
                RagioneSociale:"",
                RichiediFatturazione:false,
                Telefono:"",
                Tessera:"",
                TipoPagamento:"",
                UserName:""
            },
            CambiaPassword:{
                OldPassword:'',
                NewPassword:'',
                ConfermaPassword:''
            },
            errors:{},
            errorsText:{}
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleRistampaClick = this.handleRistampaClick.bind(this);
        this.handlePWDChange = this.handlePWDChange.bind(this);
        this.handleChangePasswordConfirm = this.handleChangePasswordConfirm.bind(this);
	}

	componentWillUnmount() {
		this.setState({open: false});
	}

    onSaveClick(){
        if(this.validation(this.state.user)===true){
            this.props.dispatch(salvaUtente(this.state.user));
        }
    }

    handleRistampaClick(){
        this.props.dispatch(ristampaTessera(this.state.user));
    }

    handleChange(e) {
        const { name, value } = e.target;
        var user = this.state.user;
        user[name]=value;
        this.setState({ user: user });
    }

    handlePWDChange(e) {
        const { name, value } = e.target;
        var pwd = this.state.CambiaPassword;
        pwd[name]=value;
        this.setState({ CambiaPassword: pwd });
    }

    handleOpenPasswordDialog = () => {
        this.props.dispatch(openPasswordDialog());
    };

    handleClosePasswordDialog = () => {
        this.props.dispatch(closePasswordDialog());
        this.setState({errors: {},errorsText:{},CambiaPassword:{}});
    };

    handleCloseEditDialog = () => {
        this.props.dispatch(closeEditDialog());
        this.setState({errors: {},errorsText:{},CambiaPassword:{}});
    };
    
    componentWillReceiveProps(nextProps){
        if (nextProps.userdetail !== this.props.userdetail) {
            console.log('here',nextProps.userdetail);
            this.setState({ user: nextProps.userdetail })
        }
    }
    
    validation(user) {
        var errors={};
        var errorsText={};
		var retVal=true;
        
		if(!user.Nome || user.Nome.length===0){
			errors['nome']= true ;
			retVal=false;
		}
        if(!user.Cognome || user.Cognome.length===0){
			errors['cognome']= true;
			retVal=false;
        }
        if(!user.Email || user.Email.length===0){
			errors['email']= true;
			retVal=false;
        }
        if(!user.Cellulare || user.Cellulare.length===0){
			errors['cellulare']= true;
			retVal=false;
        }
        if(!user.UserName || user.UserName.length===0){
			errors['username'] = true;
			retVal=false;
        }
        if(!user.RagioneSociale || user.RagioneSociale.length===0){
			errors['ragionesociale']= true;
			retVal=false;
        }
        if(!user.Piva || user.Piva.length===0){
			errors['piva']= true;
			retVal=false;
        }
        if(!user.Telefono || user.Telefono.length===0){
			errors['telefono']= true;
			retVal=false;
        }
        if(!user.PEC || user.PEC.length===0){
			errors['pec']= true;
			retVal=false;
        }
        if(!user.Indirizzo || user.Indirizzo.length===0){
			errors['indirizzo']= true;
			retVal=false;
        }
        if(!user.Provincia || user.Provincia.length===0){
			errors['provincia']= true;
			retVal=false;
        }
        if(!user.CAP || user.CAP.length===0){
			errors['cap']= true;
			retVal=false;
        }
        if(!user.Localita || user.Localita.length===0){
			errors['localita']= true;
			retVal=false;
        }
        if(!user.Paese || user.Paese.length===0){
			errors['paese']= true;
			retVal=false;
        }
		this.setState({errors: errors,errorsText:errorsText});
		return retVal;
	}

    handleChangePasswordConfirm(){
        if(this.validationPWD(this.state.CambiaPassword)===true){
             this.props.dispatch(cambiaPassword(this.state.CambiaPassword));
         }
    }

    validationPWD(pwd) {
        var errors={};
        var errorsText={};
		var retVal=true;
        
		if(!pwd.OldPassword || pwd.OldPassword.length===0){
            errors['vecchiapassword'] = true;
            errorsText['vecchiapassword']="Campo Vecchia Password obbligatorio";
			retVal=false;
        }
        else{
            errors['password'] = false;
        }
        if(!pwd.NewPassword || pwd.NewPassword.length===0){
            errors['password'] = true;
            errorsText['password']="Campo Password obbligatorio";
			retVal=false;
        }
        else{
            errors['password'] = false;
        }
        if(!pwd.ConfermaPassword || pwd.ConfermaPassword.length===0){
            errors['confermapassword']= true;
            errorsText['confermapassword']="Campo Conferma Password obbligatorio";
			retVal=false;
        }
        else{
            errors['confermapassword']= false;
        }
        if((errors['password'] == false && errors['confermapassword'] == false)&&( pwd.NewPassword!=pwd.ConfermaPassword)){
            errors['confermapassword']= true;
            errors['password'] = true;
            errorsText['password']="Password e Conferma devono coincidere";
            errorsText['confermapassword']="Password e Conferma devono coincidere";
        }
        
        this.setState({errors: errors,errorsText:errorsText});
		return retVal;
	}

	render() {
        const { classes } = this.props;
        const { dispatch,expanded,history,selected,password_dialog_open,error_message,editmessage_dialog_open } = this.props;

        console.log('aaaaaaaaaaaaaaaaaaa',this.props.isFetching);

		return (
			<div>
                {
					this.props.isFetching &&
					<div className="fullBG">
						<div className="loader">
							<div></div>
						</div>
					</div>
				}
                <CustomSideNav
                    dispatch={dispatch}
                    history={history}
                    selected={selected}
                    expanded={expanded}
                />
				<Main expanded={expanded}>
					<div>
                    <div className="header">
                        <div className="header-content clearfix">
                            <div className="page-title">
                                <p>Gestione Utente</p>
                            </div>
                            <div className="site-title">
                                <div>
                                    <p>PESA PUBBLICA</p>
                                </div>
                            </div>
                        </div>
                    </div>   
                    <Dialog
                        open={password_dialog_open}
                        onClose={this.handleClosePasswordDialog}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            Cambio Password
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Per modificare la password inserire la vecchia password e la nuova password
                            </DialogContentText>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={12}>
                                    <TextField
                                        error={this.state.errors['vecchiapassword']===true}
                                        onChange={this.handlePWDChange}
                                        name="OldPassword"
                                        autoFocus
                                        type="Password"
                                        margin="dense"
                                        label="Vecchia Password"
                                    />
                                    {this.state.errors['vecchiapassword']===true && <FormHelperText error>{this.state.errorsText['vecchiapassword']}</FormHelperText>}
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={12}>
                                    <TextField
                                        error={this.state.errors['password']===true}
                                        onChange={this.handlePWDChange}
                                        name="NewPassword"
                                        type="Password"
                                        margin="dense"
                                        label="Nuova Password"
                                    />
                                    {this.state.errors['password']===true && <FormHelperText error>{this.state.errorsText['password']}</FormHelperText>}
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={12}>
                                    <TextField
                                        error={this.state.errors['confermapassword']===true}
                                        onChange={this.handlePWDChange}
                                        name="ConfermaPassword"
                                        margin="dense"
                                        type="Password"
                                        label="Conferma Password"
                                    />
                                    {this.state.errors['confermapassword']===true && <FormHelperText error>{this.state.errorsText['confermapassword']}</FormHelperText>}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleChangePasswordConfirm} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={editmessage_dialog_open}
                        onClose={this.handleCloseEditDialog}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            Modifica Utente
                        </DialogTitle>
                        <DialogContent>
                            {error_message.length>0&&
                                <DialogContentText>
                                    <p className="error-text">Errore</p>
                                    <p className="error-text">{error_message}</p>
                                </DialogContentText>
                            }
                            {error_message.length==0&&
                                <DialogContentText>
                                    <p>Utente modificato correttamente.</p>
                                    <p>I tuoi dati sono stati aggiornati</p>
                                </DialogContentText>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseEditDialog} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>

                        <div className="utente-container">
						<Paper className='registrazione'>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <h2>Dati personali</h2>
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['nome']===true} >
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="nome"
                                        >
                                            Nome
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire il nome"
                                            onChange={this.handleChange}
                                            name="Nome"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            value={this.state.user.Nome}
                                            id="nome"
                                        />
                                        {this.state.errors['nome']===true && <FormHelperText id="name-error-text">Campo Nome obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['cognome']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="cognome"
                                        >
                                            Cognome
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire il cognome"
                                            onChange={this.handleChange}
                                            name="Cognome"
                                            value={this.state.user.Cognome}
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="cognome"
                                        />
                                        {this.state.errors['cognome']===true && <FormHelperText id="name-error-text">Campo Cognome obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            

                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl disabled className={classes.formControl} error={this.state.errors['email']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="email"
                                        >
                                            Email
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la mail"
                                            onChange={this.handleChange}
                                            name="Email"
                                            value={this.state.user.Email}
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="email"
                                        />
                                        {this.state.errors['email']===true && <FormHelperText id="name-error-text">Campo Email obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['cellulare']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="cellulare"
                                        >
                                            Cellulare
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire il cellulare"
                                            onChange={this.handleChange}
                                            name="Cellulare"
                                            value={this.state.user.Cellulare}
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="cellulare"
                                        />
                                        {this.state.errors['nome']===true && <FormHelperText id="name-error-text">Campo Cellulare obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl disabled className={classes.formControl} error={this.state.errors['username']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="username"
                                        >
                                            Username
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire lo username"
                                            onChange={this.handleChange}
                                            name="UserName"
                                            value={this.state.user.UserName}
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="username"
                                        />
                                        {this.state.errors['username']===true && <FormHelperText id="name-error-text">Campo Username obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                    <div className="print-button">
                                        <Button className={classes.button} variant="raised" color="primary" onClick={this.handleOpenPasswordDialog}>
                                            Cambia Password
                                            <ContentPassword className={classes.rightIcon} />
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <h2>Dati aziendali</h2>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                    <div className="print-button">
                                        <Button className={classes.button} variant="raised" color="primary" onClick={this.handleRistampaClick} >
                                            Ristampa tessera
                                            <ContentPrint className={classes.rightIcon} />
                                        </Button>
                                    </div>
                                </Grid>
                                 
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['ragionesociale']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="ragionesociale"
                                        >
                                            Ragione Sociale
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la ragione sociale"
                                            onChange={this.handleChange}
                                            name="RagioneSociale"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            value={this.state.user.RagioneSociale}
                                            id="ragionesociale"
                                        />
                                        {this.state.errors['ragionesociale']===true && <FormHelperText id="name-error-text">Campo Ragione Sociale obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['piva']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="piva"
                                        >
                                            Partita IVA
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la partita IVA"
                                            onChange={this.handleChange}
                                            name="Piva"
                                            value={this.state.user.Piva}
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="piva"
                                        />
                                        {this.state.errors['piva']===true && <FormHelperText id="name-error-text">Campo Partita IVA obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['telefono']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="telefono"
                                        >
                                            Telefono
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire il telefono"
                                            onChange={this.handleChange}
                                            name="Telefono"
                                            value={this.state.user.Telefono}
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="telefono"
                                        />
                                        {this.state.errors['telefono']===true && <FormHelperText id="name-error-text">Campo Telefono obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['pec']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="pec"
                                        >
                                            PEC
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la PEC"
                                            onChange={this.handleChange}
                                            value={this.state.user.PEC}
                                            name="PEC"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="pec"
                                        />
                                        {this.state.errors['pec']===true && <FormHelperText id="name-error-text">Campo PEC obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['indirizzo']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="indirizzo"
                                        >
                                            Indirizzo
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire l'indirizzo"
                                            onChange={this.handleChange}
                                            value={this.state.user.Indirizzo}
                                            name="Indirizzo"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="indirizzo"
                                        />
                                        {this.state.errors['indirizzo']===true && <FormHelperText id="name-error-text">Campo Indirizzo obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['provincia']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="provincia"
                                        >
                                            Provincia
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la provincia"
                                            onChange={this.handleChange}
                                            value={this.state.user.Provincia}
                                            name="Provincia"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="provincia"
                                        />
                                        {this.state.errors['provincia']===true && <FormHelperText id="name-error-text">Campo Provincia obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['cap']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="cap"
                                        >
                                            CAP
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire il CAP"
                                            onChange={this.handleChange}
                                            value={this.state.user.CAP}
                                            name="CAP"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="cap"
                                        />
                                        {this.state.errors['cap']===true && <FormHelperText id="name-error-text">Campo CAP obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['localita']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="località"
                                        >
                                            Località
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la località"
                                            onChange={this.handleChange}
                                            value={this.state.user.Localita}
                                            name="Localita"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="località"
                                        />
                                        {this.state.errors['localita']===true && <FormHelperText id="name-error-text">Campo Località obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['paese']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="paese"
                                        >
                                            Nazione
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la nazione"
                                            onChange={this.handleChange}
                                            value={this.state.user.Paese}
                                            name="Paese"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="paese"
                                        />
                                        {this.state.errors['paese']===true && <FormHelperText id="name-error-text">Campo Nazione obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                
                                
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <h2>Pagamento</h2>
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl disabled className={classes.formControl} error={this.state.errors['tipopagamento']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="tipopagamento"
                                        >
                                            Modalità Pagamento
                                        </InputLabel>
                                        <Input
                                            placeholder="Modalità di pagamento"
                                            onChange={this.handleChange}
                                            name="TipoPagamento"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            value={this.state.user.TipoPagamento}
                                            id="tipopagamento"
                                        />
                                        {this.state.errors['tipopagamento']===true && <FormHelperText id="name-error-text">Campo Ragione Sociale obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                <FormControlLabel
                                    classes={{
                                       root:classes.toggleRoot
                                    }}
                                    control={
                                        <Switch
                                        checked={this.state.checkedF}
                                      //  onChange={this.handleChange('checkedF')}
                                        value="checkedF"
                                        classes={{
                                            checked: classes.checked,
                                            bar: classes.bar
                                        }}
                                        />
                                    }
                                    label="Fatturazione automatica"
                                    />
                                </Grid>
                            </Grid>
                            <div className="button-container">
                                <Button className={classes.button} variant="raised" color="primary" onClick={() => {this.onSaveClick()}}>
                                    Conferma
                                </Button>
                                
                            </div>
						</Paper>
                        </div>
					</div>
                </Main>
			</div>
		)
	}
}
/*
<Button className={classes.button} variant="raised" color="primary" onClick={() => {this.props.history.push("/");}}>
                                    Annulla
                                </Button>
*/ 
GestioneUtente.propTypes = {
	dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { sidemenu,edituser,userinfo,tessera } = state
    const { userdetail } = userinfo
    const { expanded,selected } = sidemenu
    var tesFetching = tessera.isFetching; 
    var userFetching = edituser.isFetching;
    const { password_dialog_open,error_message,editmessage_dialog_open } = edituser
    var isFetching = userFetching||tesFetching;
    return {
        isFetching,
        expanded,
        selected,
        password_dialog_open,
        userdetail,
        error_message,
        editmessage_dialog_open
    }
}

export default withStyles(stile)(connect(mapStateToProps)(GestioneUtente));