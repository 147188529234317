import { FATTURE_REQUEST, FATTURE_SUCCESS, FATTURE_FAILURE } from '../actions';
import { PRINT_FATTURA_REQUEST, PRINT_FATTURA_SUCCESS, PRINT_FATTURA_FAILURE } from '../actions';
// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.

function download(strData, strFileName, strMimeType) {
    var D = document, A = arguments, a = D.createElement("a"),
         d = A[0], n = A[1], t = A[2] || "application/pdf";

    var newdata = "data:" + strMimeType + ";base64," + escape(strData);

    //build download link:
    a.href = newdata;

    if ('download' in a) {
        a.setAttribute("download", n);
        a.innerHTML = "downloading...";
        D.body.appendChild(a);
            setTimeout(function () {
                var e = D.createEvent("MouseEvents");
                e.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null
            );
            a.dispatchEvent(e);
            D.body.removeChild(a);
        }, 66);
        return true;
    };
}


export default function userinfo(state = {
	isFetching: false,
	isAuthenticated: localStorage.getItem('id_token') ? true : false,
	fatture_list:[]
	}, action) {
	switch (action.type) {
		case FATTURE_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			})
		case FATTURE_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
                errorMessage: '',
                authenticated: action.authenticated || false,
				fatture_list:action.response.data
			})
		case FATTURE_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				errorMessage: action.message
            })
        
        case PRINT_FATTURA_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                errorMessage:''
            })
        case PRINT_FATTURA_SUCCESS:
            download(action.response.data.File,action.response.data.Nome,'application/pdf')
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: ''
            })
        case PRINT_FATTURA_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.error
            })
		default:
			return state
	}
}