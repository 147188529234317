// components/Login.js

import React, { Component } from 'react'
import PropTypes from 'prop-types';

import styled from 'styled-components';
import CustomSideNav from './CustomSideNav'
import { connect } from 'react-redux'
import purple from 'material-ui/colors/purple';
import { withStyles } from 'material-ui/styles';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../css/react_dates_overrides.css';

import { MenuItem } from 'material-ui/Menu';
import PoiIcon from '../img/poiIcon.svg'
import PhoneIcon from '../img/phone.svg'
import EmailIcon from '../img/mail.svg'
import '../css/contatti.css';
import CustomMap from './map';
var moment = require('moment');
require('moment/locale/it');





const Main = styled.main`
    position: relative;
    overflow: hidden;
    transition: all .15s;
    padding: 0 0px;
    z-index: 1007;
    display:block;
    margin-left: ${props => (props.expanded ? 230 : 55)}px;
`;

const stile = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
    },
    inputLabelFocused: {
        color: purple[500],
    },
    inputInkbar: {
        '&:after': {
            backgroundColor: purple[500],
        },
    },
    textFieldRoot: {
        padding: 0,
        'label + &': {
            marginTop: theme.spacing.unit * 3,
        },
    },
    inputRoot:{
        padding: '8px 8px 10px 8px',
        border: '1px solid #dbdbdb',
        '&:after':{
            height:0
        },
        '&:before':{
            height:0
        }
    },
    buttonRoot:{
        marginRight:20
    },
    textFieldInput: {
        borderRadius: 3,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '9px 12px',
        margin:'10px',
        width: 'calc(100% - 24px)',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: '#989898',
            boxShadow: '0 0 0 0.1rem rgba(101,101,101,.25)',
        },
    },
    textFieldFormLabel: {
        fontSize: 18,
    },
});

class Contatti extends Component {

    constructor(props) {
        super(props);

        this.state = {
			
        };
        
        
    }

    

    render() {
        const { classes } = this.props;
        const { errorMessage,dispatch,expanded,history,selected,causali,associapesata_dialog_open,associa_error_message } = this.props;

        console.log(this.props);

        var itemscausali=[];
		
		if(causali.length>0){
            itemscausali.push(<MenuItem key="0" value="null" disabled>Causale</MenuItem>);
            console.log(causali);
            causali.forEach(item => itemscausali.push(<MenuItem key={item.ID} value={item.ID}>{item.Descrizione}</MenuItem>));
        }
            
            console.log(itemscausali);
        return (
            
            <div>
           
                <CustomSideNav
                    dispatch={dispatch}
                    errorMessage={errorMessage}
                    history={history}
                    selected={selected}
                    expanded={expanded}
                />
                <Main expanded={expanded}>

                    <div className="header">
                        <div className="header-content clearfix">
                            <div className="page-title">
                                <p>Contatti</p>
                            </div>
                            <div className="site-title">
                                <div>
                                    <p>PESA PUBBLICA</p>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper">
                        <div className="contattiWrapper">
                            <div className="title">
                                    Industrial Software
                            </div>
                            <div className="contatti">
                                <div className="contatto">
                                    <div className="contattoImg">
                                        <div className="bg"></div>
                                        <img src={PoiIcon}/>
                                    </div>
                                    <div className="contattoInfo">
                                        <h3>Indirizzo:</h3>
                                        <a 
                                            target="_blank" 
                                            href="https://www.google.it/maps/dir//Via+Frassina,+51,+54033+Carrara+MS/@44.044586,10.0803218,605m/data=!3m1!1e3!4m8!4m7!1m0!1m5!1m1!1s0x12d50f5f1684d969:0x445056c38fc2d719!2m2!1d10.0825105!2d44.044586">
                                                Via Frassina 51
                                        </a>
                                    </div>
                                
                                </div>
                                <div className="contatto">
                                    <div className="contattoImg">
                                        <div className="bg"></div>
                                        <img src={PhoneIcon}/>
                                    </div>
                                    <div className="contattoInfo">
                                        <h3>Telefono:</h3>
                                        <a href="tel:0585-251380">0585-251380</a>
                                    </div>
                                </div>
                                <div className="contatto">
                                    <div className="contattoImg">
                                        <div className="bg"></div>
                                        <img src={EmailIcon}/>
                                    </div>
                                    <div className="contattoInfo">
                                        <h3>Email:</h3>
                                        <a href="MAILTO:support@industrialsoftware.it">support@industrialsoftware.it</a>
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                        <div className="map">
                            <CustomMap lng={44.044586} lat={10.0823737}/>
                        </div>
                        
                        
                        

                    </div>
                   
                </Main>
            </div>
        )
    }
}

Contatti.propTypes = {
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    history: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    const { sidemenu,weight } = state
    const {weight_list,causali,associapesata_dialog_open,associa_error_message} = weight
    const { expanded,selected } = sidemenu
    return {
        expanded,
        selected,
        weight_list,
        causali,
        associapesata_dialog_open,
        associa_error_message
    }
}

export default withStyles(stile)(connect(mapStateToProps)(Contatti));

/*
<NavItem eventKey="settings">
<NavIcon>
<i className="icon-address" style={{ fontSize: '1.5em', verticalAlign: 'middle' }} />
</NavIcon>
<NavText style={{ paddingRight: 32 }}>
Gestione Trasferte
</NavText>
<NavItem eventKey="settings/policy">
<NavText>
Policy
</NavText>
</NavItem>
<NavItem eventKey="settings/network">
<NavText>
Network
</NavText>
</NavItem>
</NavItem>
*/