import React, { Component } from 'react'
import PropTypes from 'prop-types';
import styles from '../css/app.css'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import styled from 'styled-components';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from './StyledSideNav';
//import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

import { toggleSideNav, navigateTo, getUserInfo } from '../actions/navBarAction'
import { Link } from 'react-router-dom'
import logo from '../img/logo.png'
import logomin from '../img/logomin.png'
import { connect } from 'react-redux'

import { logoutUser } from '../actions/userAction' 

const NavHeader = styled.div`
    display: ${props => (props.expanded ? 'block' : 'none')};
    white-space: nowrap;
    background-color: rgb(0, 121, 191);;
    color: #fff;
    > * {
        color: inherit;
        background-color: inherit;
    }
`;

// height: 20px + 10px + 10px = 40px
const NavTitle = styled.div`
    font-size: 1.5em;
    line-height: 20px;
    padding: 10px 0;
`;

// height: 20px + 4px = 24px;
const NavSubTitle = styled.div`
    font-size: 1em;
    line-height: 20px;
    padding-bottom: 4px;
`;

class CustomSideNav extends Component {
	constructor(props) {
		super(props);
		this.onSelect = this.onSelect.bind(this);
		this.onToggle = this.onToggle.bind(this);
	}

	componentWillUnmount() {
		this.setState({open: false});
    }
    
    componentDidMount(){
        this.props.dispatch(getUserInfo());
    }

	onSelect(selected){
		const to = '/' + selected;
		this.props.history.push(to);
        this.props.dispatch(toggleSideNav(false));
        if(selected=="login")
            selected="home";
		this.props.dispatch(navigateTo(selected));
	}

	onToggle(toggled){
		this.props.dispatch(toggleSideNav());
	}

	render() {
        console.log(this.props.userdetail);
        const { errorMessage,dispatch,selected,expanded } = this.props

		return (
			<div>
				<SideNav onSelect={this.onSelect} onToggle={this.onToggle}>
					<Toggle />
					<NavHeader expanded={expanded}>
                        <NavTitle>Benvenuto</NavTitle>
                        <NavSubTitle>{this.props.userdetail.Nome} {this.props.userdetail.Cognome}</NavSubTitle>
                    </NavHeader>
					<Nav defaultSelected={selected} style={{marginTop: 10}}>
						<NavItem eventKey="home" style={{ height: '60px' }}>
							<NavIcon>
								<i className="flaticon-balance" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
							</NavIcon>
							<NavText>
								<span style={{ fontSize: '125%', marginLeft: '10px' }}>Pesate</span>
							</NavText>
						</NavItem>
                        <NavItem eventKey="prenotazioni" style={{ height: '60px' }}>
							<NavIcon>
								<i className="flaticon-agenda" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
							</NavIcon>
							<NavText style={{ paddingRight: 32, paddingLeft:10 }}>
								<span style={{ fontSize: '125%', marginLeft: '10px' }}>Prenotazioni</span>
							</NavText>
						</NavItem>
						<NavItem eventKey="fatture" style={{ height: '60px' }}>
							<NavIcon>
								<i className="flaticon-receipt" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
							</NavIcon>
							<NavText style={{ paddingRight: 32, paddingLeft:10 }}>
								<span style={{ fontSize: '125%', marginLeft: '10px' }}>Fatture</span>
							</NavText>
						</NavItem>
						<NavItem eventKey="contatti" style={{ height: '60px' }}>
							<NavIcon>
								<i className="flaticon-customer-support" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
							</NavIcon>
							<NavText style={{ paddingRight: 32, paddingLeft:10 }}>
								<span style={{ fontSize: '125%', marginLeft: '10px' }}>Assistenza</span>
							</NavText>
						</NavItem>
						<NavItem eventKey="gestioneutente" style={{ height: '60px' }}>
							<NavIcon>
								<i className="flaticon-user" style={{ fontSize: '1.5em', verticalAlign: 'middle' }} />
							</NavIcon>
							<NavText style={{ paddingRight: 32, paddingLeft:10 }}>
								<span style={{ fontSize: '125%', marginLeft: '10px' }}>Gestione Profilo</span>
							</NavText>
						</NavItem>
						<NavItem eventKey="login" onClick={() => this.props.dispatch(logoutUser())} style={{ height: '60px' }}>
							<NavIcon>
								<i className="flaticon-logout" style={{ fontSize: '1.5em', verticalAlign: 'middle' }} />
							</NavIcon>
							<NavText style={{ paddingRight: 32, paddingLeft:10 }}>
								<span style={{ fontSize: '125%', marginLeft: '10px' }}>Logout</span>
							</NavText>
						</NavItem>
						<div className="side-logo-container">
							{!expanded && <img src={logomin} alt="ISLogo" height="50" width="33"/>}
							{expanded && <img src={logo} alt="ISLogo" height="50" width="122"/>}
						</div>
					</Nav>
				</SideNav>
			</div>
		)
	}
}

CustomSideNav.propTypes = {
	history: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	errorMessage: PropTypes.string,
	selected:PropTypes.string.isRequired,
	expanded:PropTypes.bool.isRequired
}

function mapStateToProps(state) {
    const { userinfo } = state
    const { userdetail } = userinfo
    return {
        userdetail
    }
}

export default connect(mapStateToProps)(CustomSideNav);

/*
<NavItem eventKey="settings">
	<NavIcon>
		<i className="icon-address" style={{ fontSize: '1.5em', verticalAlign: 'middle' }} />
	</NavIcon>
	<NavText style={{ paddingRight: 32, paddingLeft:10 }}>
		Gestione Trasferte
	</NavText>
	<NavItem eventKey="settings/policy">
		<NavText>
			Policy
		</NavText>
	</NavItem>
	<NavItem eventKey="settings/network">
		<NavText>
			Network
		</NavText>
	</NavItem>
</NavItem>
*/