// components/Login.js

import React, { Component } from 'react'
import PropTypes from 'prop-types';
import LockIcon from 'material-ui-icons/Lock';
import TextField from 'material-ui/TextField';
import Button from 'material-ui/Button';
import Paper from 'material-ui/Paper';
import styles from '../css/app.css'
import styled from 'styled-components';
import CustomSideNav from './CustomSideNav'
import { connect } from 'react-redux'
import logo from '../img/logo.png'
import purple from 'material-ui/colors/purple';
import { withStyles } from 'material-ui/styles';
import ReactDataGrid from 'react-data-grid';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../css/react_dates_overrides.css';
import { getPrenotazioni,deletePrenotazione,closeEditPrenotazione, openEditPrenotazione,getPrenotazione,savePrenotazione,closeEsitoPrenotazioneDialog }  from '../actions/prenotazioniAction';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import { MenuItem } from 'material-ui/Menu';
import Select from 'material-ui/Select';
import Input, { InputLabel } from 'material-ui/Input';
import { FormControl, FormHelperText } from 'material-ui/Form';
import Tooltip from 'material-ui/Tooltip';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from 'material-ui/Dialog';
import Grid from 'material-ui/Grid';

import ContentAdd from 'material-ui-icons/Add';
import ContentEdit from 'material-ui-icons/Edit';
import ContentDelete from 'material-ui-icons/Delete';
import DoneIcon from 'material-ui-icons/Done';

var moment = require('moment');
require('moment/locale/it');
console.log(moment.locale()); // cs

class DateFormatter extends React.Component {
    static propTypes = {
      value: PropTypes.number.isRequired
    };
  
    render() {
      const percentComplete = this.props.value + '%';
      return (
        <div>
            { new moment(this.props.value).format("D/M/YYYY") }
        </div>);
    }
  }

//Columns definition
var columns = [
    {
        key: '',
        name: '',
        width: 15
    },
    {
      key: 'ID',
      name: 'ID',
      width: 75
    },
    {
        key: 'Data',
        name: 'Data',
        formatter : DateFormatter,
        width: 150
      },
    {
      key: 'NBooking',
      name: 'NBooking',
      width: 100
    },
    {
      key: 'NContainer',
      name: 'Container',
      sortable : true,
      width: 150,
      resizable : true
    },
    {
        key: 'Targa',
        name: 'Targa',
        width: 150
    },
    {
        key: 'TaraMezzo',
        name: 'TaraMezzo',
        width: 150
    },
    {
        key: 'Stato',
        name: 'Stato',
        width: 400
    },
    
];

const Main = styled.main`
    position: relative;
    overflow: hidden;
    transition: all .15s;
    padding: 0 0px;
    z-index: 1007;
    display:block;
    margin-left: ${props => (props.expanded ? 230 : 55)}px;
`;

const stile = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
    },
    inputLabelFocused: {
        color: 'rgb(0, 121, 191)',
      },
      inputInkbar: {
        '&:after': {
          backgroundColor: 'rgb(0, 121, 191)',
        },
      },
    textFieldRoot: {
        padding: 0,
        'label + &': {
            marginTop: theme.spacing.unit * 3,
        },
    },
    inputRoot:{
        padding: '8px 8px 10px 8px',
        border: '1px solid #dbdbdb',
        '&:after':{
            height:0
        },
        '&:before':{
            height:0
        }
    },
    dialogRoot:{
        'min-width': 875,
       
    },
    buttonRoot:{
        marginRight:20
    },
    selectMenu:{
        overflow: 'inherit'
    },
    textFieldInput: {
        borderRadius: 3,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '9px 12px',
        margin:'10px',
        width: 'calc(100% - 24px)',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: '#989898',
            boxShadow: '0 0 0 0.1rem rgba(101,101,101,.25)',
        },
    },
    textFieldFormLabel: {
        fontSize: 18,
    },
});

class Prenotazioni extends Component {

    constructor(props) {
        super(props);

        this.state = {
			selectedRow:null,
			selectedRows:null,
			rows:null,
            selectedIndexes:[],
            da:moment().subtract(1, 'months'),
            a:moment(),
            openDeleteDialog:false,
            errors:{},
            errorsText:{},
            prenotazione_detail:{
                Persona_Autorizzata:{},
                Shipper:{}
            },
        };
        
        this.handleChange = this.handleChange.bind(this);

        this.handlePAutChange = this.handlePAutChange.bind(this);
        this.handleShipChange = this.handleShipChange.bind(this);
        this.handlePrenChange = this.handlePrenChange.bind(this);
    }

    handleShipChange = (e,elem,val)=>{
        const { name, value } = e.target;
        var shipping = this.state.prenotazione_detail;
        console.log(this.state);
        shipping['Shipper'][name]=value
        this.setState({ prenotazione_detail: shipping });
    }
    
    handlePAutChange = (e,elem,val)=>{
        const { name, value } = e.target;
        var paut = this.state.prenotazione_detail;
        paut['Persona_Autorizzata'][name]=value
        this.setState({ Persona_Autorizzata: paut });
    }

    handlePrenChange = (e,elem,val)=>{
        const { name, value } = e.target;
        var pren = this.state.prenotazione_detail;
        pren[name]=value
        this.setState({ Persona_Autorizzata: pren });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    componentWillUnmount() {
        this.setState({open: false});
    }

    componentDidMount(){
        this.props.dispatch(getPrenotazioni({da:this.state.da.format('YYYY-MM-DD'),a:moment(this.state.a).add(1, 'days').format('YYYY-MM-DD')}));
        
    }

    handleGridSort = (sortColumn, sortDirection) => {
		var comparer = function(a, b) {
	      if(sortDirection === 'ASC'){
	        return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
	      }else if(sortDirection === 'DESC'){
	        return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
	      }
	    }
	    var rows_app = this.state.rows === null ? this.props.prenotazioni_list.slice(0) : this.state.rows;
	    var rows = sortDirection === 'NONE' ? this.props.prenotazioni_list.slice(0) : rows_app.sort(comparer);
	    this.setState({rows : rows,selectedRow:null,selectedRows:null,selectedIndexes:[]});
    };
    
    rowGetter = (i) => {
        return this.state.rows!==null ? this.state.rows[i] : this.props.prenotazioni_list[i];
    };
  
    onRowsSelected = (rows) => {
        /*if(rowNum.length!=0){
            this.setState({selectedRow: this.props.prenotazioni_list[rowNum],selectedRows:rowNum});
        }
        else{
            this.setState({selectedRow: null,selectedRows:null});
        }*/
        var rowNum = rows.map(r => r.rowIdx)[0];
        this.setState({selectedRow: rows[0].row,selectedRows:rowNum,selectedIndexes: rows.map(r => r.rowIdx)});
    };
  
    onRowsDeselected = (rows) => {
        this.setState({selectedRow: null,selectedRows:null,selectedIndexes: []});
    };
  
    onRowClick = (rowIdx, row) => {
        if(rowIdx===this.state.selectedRows){
            this.setState({selectedRow: null,selectedRows:null,selectedIndexes: []});
        }
        else{
            this.setState({selectedRow: row,selectedRows:rowIdx,selectedIndexes: [rowIdx]});
        }

        /* rows[rowIdx] = Object.assign({}, row, {isSelected: !row.isSelected});
        this.setState({rows});*/
    }


    componentDidUpdate = () => {
        setTimeout(() => {
            if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
                var evt = document.createEvent('UIEvents');
                evt.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(evt);
               } else {
                  window.dispatchEvent(new Event('resize'));
           
               }
            //document.getElementsByClassName("react-grid-Canvas")[0].click()
        }, 50);
        
    }

    handleChange = (e,elem,val)=>{
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    
    isButtonDisabled = () => {
		return this.state.selectedRow===null;
    };
    
    handleDeletePrenotazioneClick = ()=>{
        this.setState({ openDeleteDialog: true });
    }
    
    handleCloseCancellaDialog = ()=>{
        this.setState({ openDeleteDialog: false });
    }

    handleDeleteConfirm = ()=>{
        this.props.dispatch(deletePrenotazione(this.state.selectedRow.ID));
        this.setState({ openDeleteDialog: false });
        this.setState({selectedRow: null,selectedRows:null,selectedIndexes: []});
    }

    handleOpenPrenotazioneModal = ()=> {
        if(this.state.selectedRow!==null)
            this.props.dispatch(getPrenotazione(this.state.selectedRow.ID));
        this.props.dispatch(openEditPrenotazione());
   }

    handleClosePrenotazioneModal = ()=> {
        this.props.dispatch(closeEditPrenotazione());
    }

    handleSavePrenotazione=()=>{
        this.props.dispatch(savePrenotazione(this.state.prenotazione_detail));
    }

    handleCloseEsitoPrenotazione=()=>{
        this.props.dispatch(closeEsitoPrenotazioneDialog());
    }
    
    updatePrenotazioniList=(filter)=>{
        this.props.dispatch(getPrenotazioni(filter));
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.prenotazione_detail !== this.props.prenotazione_detail) {
            console.log('here',nextProps.prenotazione_detail);
            var pren=nextProps.prenotazione_detail;
            if(nextProps.prenotazione_detail!==null){
                if(pren.Shipper==null)
                    pren.Shipper={}
                if(pren.Persona_Autorizzata==null)
                    pren.Persona_Autorizzata={}
            }
            this.setState({ prenotazione_detail: pren })
        }


        if (nextProps.editprenotazione_dialog_open==false && this.props.editprenotazione_dialog_open==true) {
            this.props.dispatch(getPrenotazioni({da:this.state.da.format('YYYY-MM-DD'),a:moment(this.state.a).add(1, 'days').format('YYYY-MM-DD')}));
        }

        /*if (nextProps.editprenotazione_dialog_open==false && this.props.editprenotazione_dialog_open==true) {
            this.props.dispatch(getPrenotazioni({da:this.state.da.format('YYYY-MM-DD'),a:moment(this.state.a).add(1, 'days').format('YYYY-MM-DD')}));
        }*/
        
    }

    render() {
        const { classes } = this.props;
        const { errorMessage,dispatch,expanded,history,selected,editprenotazione_dialog_open,prenotazione_detail,prenotazioni_esito_dialog } = this.props;
        return (
            <div>
                {
					this.props.isFetching &&
					<div className="fullBG">
						<div className="loader">
							<div></div>
						</div>
					</div>
				}
                <CustomSideNav
                    dispatch={dispatch}
                    errorMessage={errorMessage}
                    history={history}
                    selected={selected}
                    expanded={expanded}
                />
                <Main expanded={expanded}>
                    <div className="header">
                        <div className="header-content clearfix">
                            <div className="page-title">
                                <p>Prenotazioni</p>
                            </div>
                            <div className="site-title">
                                <div>
                                    <p>PESA PUBBLICA</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <Dialog
                        open={prenotazioni_esito_dialog}
                        onClose={this.handleCloseEsitoPrenotazione}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            Prenotazioni
                        </DialogTitle>
                        <DialogContent>
                            {errorMessage && errorMessage.length>0 &&
                                <DialogContentText>
                                    <p className="error-text">{errorMessage}</p>
                                </DialogContentText>
                            }
                            {errorMessage && errorMessage.length==0 &&
                                <DialogContentText>
                                    <p>Prenotazione aggiornata correttamente</p>
                                    <p>I dati sono stati registrati</p>
                                </DialogContentText>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseEsitoPrenotazione} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.openDeleteDialog}
                        onClose={this.handleCloseCancellaDialog}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            Cancella prenotazioni
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Premere su conferma per cancellare la prenotazione selezionata
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDeleteConfirm} color="primary">
                                Conferma
                            </Button>
                            <Button onClick={this.handleCloseCancellaDialog} color="primary">
                                Annulla
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={editprenotazione_dialog_open}
                        onClose={this.handleClosePrenotazioneModal}
                        aria-labelledby="form-dialog-title"
                        classes={{
                            paper: classes.dialogRoot 
                        }}
                    >
                        <DialogTitle id="form-dialog-title">
                            Gestione Prenotazione
                        </DialogTitle>
                        <DialogContent
                        >
                            <DialogContentText>
                                Compilare i dati della prenotazione sottostante
                            </DialogContentText>
                            <div className="shipping-container">
                                <Grid container spacing={24}>
                                    <Grid item xs={12}  sm={6}>
                                        <h2>Dati prenotazione</h2>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['targa']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['targa']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['targa']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="targa"
                                            >
                                                Targa
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire targa del mezzo"
                                                onChange={this.handlePrenChange}
                                                name="Targa"
                                                classes={{
                                                    underline: this.state.errors['targa']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.prenotazione_detail.Targa}
                                                id="targa"
                                            />
                                            {this.state.errors['targa']===true && <FormHelperText id="name-error-text">Campo Tara Mezzo obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['nbooking']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['taramezzo']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['taramezzo']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="taramezzo"
                                            >
                                                Tara Mezzo
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire tara del mezzo"
                                                onChange={this.handlePrenChange}
                                                name="TaraMezzo"
                                                classes={{
                                                    underline: this.state.errors['taramezzo']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.prenotazione_detail.TaraMezzo}
                                                id="taramezzo"
                                            />
                                            {this.state.errors['taramezzo']===true && <FormHelperText id="name-error-text">Campo Tara Mezzo obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['ncontainer']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['ncontainer']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['ncontainer']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="ncontainer"
                                            >
                                                NContainer
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire Num. Container"
                                                onChange={this.handlePrenChange}
                                                name="NContainer"
                                                classes={{
                                                    underline: this.state.errors['ncontainer']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.prenotazione_detail.NContainer}
                                                id="ncontainer"
                                            />
                                            {this.state.errors['ncontainer']===true && <FormHelperText id="name-error-text">Campo NBooking obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['nbooking']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['prodotto']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['prodotto']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="prodotto"
                                            >
                                                Prodotto
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire Prodotto"
                                                onChange={this.handlePrenChange}
                                                name="Prodotto"
                                                classes={{
                                                    underline: this.state.errors['nbooking']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.prenotazione_detail.Prodotto}
                                                id="prodotto"
                                            />
                                            {this.state.errors['prodotto']===true && <FormHelperText id="name-error-text">Campo Prodotto obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['nbooking']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['nbooking']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['nbooking']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="nbooking"
                                            >
                                                Num. Booking
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire Num. Booking"
                                                onChange={this.handlePrenChange}
                                                name="NBooking"
                                                classes={{
                                                    underline: this.state.errors['nbooking']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.prenotazione_detail.NBooking}
                                                id="nbooking"
                                            />
                                            {this.state.errors['nbooking']===true && <FormHelperText id="name-error-text">Campo NBooking obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}  sm={6}>
                                        <h2>Shipper</h2>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['shipper_nom']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['shipper_nom']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['shipper_nom']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="shipper_nom"
                                            >
                                                Nominativo
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire il nominativo"
                                                onChange={this.handleShipChange}
                                                name="Nominativo"
                                                classes={{
                                                    underline: this.state.errors['shipper_nom']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.prenotazione_detail.Shipper.Nominativo}
                                                id="shipper_nom"
                                            />
                                            {this.state.errors['shipper_nom']===true && <FormHelperText id="name-error-text">Campo Nominativo obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['shipper_email']===true}>
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['shipper_email']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['shipper_email']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="shipper_email"
                                            >
                                                Email
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire la mail"
                                                onChange={this.handleShipChange}
                                                name="Email"
                                                value={this.state.prenotazione_detail.Shipper.Email}
                                                classes={{
                                                    underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                                }}
                                                id="shipper_email"
                                            />
                                            {this.state.errors['shipper_email']===true && <FormHelperText id="name-error-text">Campo Email obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['shipper_telefono']===true}>
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['shipper_telefono']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['shipper_telefono']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="shipper_telefono"
                                            >
                                                Telefono
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire numero di telefono"
                                                onChange={this.handleShipChange}
                                                name="Telefono"
                                                value={this.state.prenotazione_detail.Shipper.Telefono}
                                                classes={{
                                                    underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                                }}
                                                id="shipper_telefono"
                                            />
                                            {this.state.errors['shipper_telefono']===true && <FormHelperText id="name-error-text">Campo Telefono obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}  sm={6}>
                                        <h2>Persona Autorizzata</h2>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['pautorizzata_nom']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['pautorizzata_nom']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['pautorizzata_nom']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="pautorizzata_nom"
                                            >
                                                Nominativo
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire il nominativo"
                                                onChange={this.handlePAutChange}
                                                name="Nominativo"
                                                classes={{
                                                    underline: this.state.errors['pautorizzata_nom']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.prenotazione_detail.Persona_Autorizzata.Nominativo}
                                                id="pautorizzata_nom"
                                            />
                                            {this.state.errors['pautorizzata_nom']===true && <FormHelperText id="name-error-text">Campo Nominativo obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['pautorizzata_email']===true}>
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['pautorizzata_email']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['pautorizzata_email']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="pautorizzata_email"
                                            >
                                                Email
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire la mail"
                                                onChange={this.handlePAutChange}
                                                name="Email"
                                                value={this.state.prenotazione_detail.Persona_Autorizzata.Email}
                                                classes={{
                                                    underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                                }}
                                                id="pautorizzata_email"
                                            />
                                            {this.state.errors['pautorizzata_email']===true && <FormHelperText id="name-error-text">Campo Email obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['pautorizzata_telefono']===true}>
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['pautorizzata_telefono']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['pautorizzata_telefono']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="pautorizzata_telefono"
                                            >
                                                Telefono
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire numero di telefono"
                                                onChange={this.handlePAutChange}
                                                name="Telefono"
                                                value={this.state.prenotazione_detail.Persona_Autorizzata.Telefono}
                                                classes={{
                                                    underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                                }}
                                                id="pautorizzata_telefono"
                                            />
                                            {this.state.errors['pautorizzata_telefono']===true && <FormHelperText id="name-error-text">Campo Telefono obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClosePrenotazioneModal} color="primary">
                                Annulla
                            </Button>
                            <Button onClick={this.handleSavePrenotazione} color="primary">
                                Conferma
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <div className="filter-container">
                        <div className="filter-content">
                            <p className="picker-label"><span>Da</span></p>
                            <SingleDatePicker
                                date={this.state.da} // momentPropTypes.momentObj or null
                                onDateChange={(date) => {this.setState({ da:date }); this.updatePrenotazioniList({da:date.format('YYYY-MM-DD'),a:moment(this.state.a).add(1, 'days').format('YYYY-MM-DD')}); }} // PropTypes.func.isRequired
                                focused={this.state.focusedDa} // PropTypes.bool
                                onFocusChange={({ focused }) => this.setState({ focusedDa:focused })} // PropTypes.func.isRequired
                                isOutsideRange ={() => false }
                            />
                            <p className="picker-label"><span>A</span></p>
                            <SingleDatePicker
                                date={this.state.a} // momentPropTypes.momentObj or null
                                onDateChange={(date) => {this.setState({ a:date }); this.updatePrenotazioniList({da:this.state.da.format('YYYY-MM-DD'),a:moment(date).add(1, 'days').format('YYYY-MM-DD')}); }} // PropTypes.func.isRequired
                                focused={this.state.focusedA} // PropTypes.bool
                                onFocusChange={({ focused }) => this.setState({ focusedA:focused })} // PropTypes.func.isRequired
                                isOutsideRange ={() => false }
                            />
                            
                        </div>
                    </div>
                    <div className="main-container grid-container">
                    <div className="action-button">
                    <Tooltip title="Crea prenotazione" placement="bottom">
                            <Button
                                color="primary"
                                variant="fab"
                                classes={{
                                    root: classes.buttonRoot,
                                }}
                                data-for='add'
                                disabled={!this.isButtonDisabled()}
                                mini={true}
                                onClick={this.handleOpenPrenotazioneModal}
                            >
                                <ContentAdd />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Modifica prenotazione" placement="bottom">
                            <Button
                                variant="fab"
                                color="primary"
                                data-tip=''
                                classes={{
                                    root: classes.buttonRoot,
                                }}
                                data-for='edit'
                                disabled={this.isButtonDisabled()}
                                mini={true}
                                onClick={this.handleOpenPrenotazioneModal}
                            >
                                <ContentEdit />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Elimina prenotazione" placement="bottom">
                            <Button
                                variant="fab"
                                color="primary"
                                data-tip=''
                                classes={{
                                    root: classes.buttonRoot,
                                }}
                                data-for='edit'
                                disabled={this.isButtonDisabled()}
                                mini={true}
                                onClick={this.handleDeletePrenotazioneClick}
                            >
                                <ContentDelete />
                            </Button>
                        </Tooltip>
                    
                    </div>
                    <ReactDataGrid
                            onGridSort={this.handleGridSort}
                            columns={columns}
                            rowGetter={this.rowGetter}
                            rowsCount={this.state.rows!==null ? this.state.rows.length : this.props.prenotazioni_list.length}
                            minHeight={320}
                            onRowUpdated={this.handleRowUpdated}
                            rowSelection={{
                                    showCheckbox: true,
                                    enableShiftSelect: false,
                                    onRowsSelected: this.onRowsSelected,
                                    onRowsDeselected: this.onRowsDeselected,
                                    selectBy: {
                                        indexes: this.state.selectedIndexes
                                    }
                            }}
                            onRowClick={this.onRowClick}
                    />
                    </div>
                </Main>
            </div>
        )
    }
}

Prenotazioni.propTypes = {
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    history: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    const { sidemenu,weight,prenotazioni } = state
    
    const {isFetching,prenotazioni_list,editprenotazione_dialog_open,prenotazione_detail,errorMessage,prenotazioni_esito_dialog} = prenotazioni
    console.log(errorMessage);
    const { expanded,selected } = sidemenu
    return {
        isFetching,
        expanded,
        selected,
        prenotazioni_list,
        editprenotazione_dialog_open,
        prenotazione_detail,
        errorMessage,
        prenotazioni_esito_dialog
    }
}

export default withStyles(stile)(connect(mapStateToProps)(Prenotazioni));

/*
<NavItem eventKey="settings">
<NavIcon>
<i className="icon-address" style={{ fontSize: '1.5em', verticalAlign: 'middle' }} />
</NavIcon>
<NavText style={{ paddingRight: 32 }}>
Gestione Trasferte
</NavText>
<NavItem eventKey="settings/policy">
<NavText>
Policy
</NavText>
</NavItem>
<NavItem eventKey="settings/network">
<NavText>
Network
</NavText>
</NavItem>
</NavItem>
*/