// The middleware to call the API for quotes
import { CALL_API } from '../middleware/api'
import { WEIGHT_LIST_REQUEST, WEIGHT_LIST_SUCCESS, WEIGHT_LIST_FAILURE } from '../actions';
import { ASSOCIA_PESATA_REQUEST, ASSOCIA_PESATA_SUCCESS, ASSOCIA_PESATA_FAILURE } from '../actions';
import { SAVE_SHIPPING_REQUEST, SAVE_SHIPPING_SUCCESS, SAVE_SHIPPING_FAILURE } from '../actions';
import { PRINT_SHIPPING_REQUEST, PRINT_SHIPPING_SUCCESS, PRINT_SHIPPING_FAILURE } from '../actions';
import { OPEN_ASSOCIAPESATA_DIALOG, CLOSE_ASSOCIAPESATA_DIALOG } from '../actions';
import { WEIGHT_DETAIL_REQUEST, WEIGHT_DETAIL_SUCCESS, WEIGHT_DETAIL_FAILURE } from '../actions';
import { OPEN_DETTAGLIOPESATA_DIALOG, CLOSE_DETTAGLIOPESATA_DIALOG } from '../actions';
import { OPEN_EDITSHIPPING_DIALOG, CLOSE_EDITSHIPPING_DIALOG } from '../actions';
import { CLOSE_MESSAGGIO_DIALOG } from '../actions';

const serialize = function (filter){
    var str = [];
    for(var p in filter)
        if (filter.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(filter[p]));
        }
    return str.join("&");
}

export function getWeightList(filter) {
    return {
        [CALL_API]: {
            endpoint: 'pesate?'+serialize(filter),
            authenticated: true,
            types: [WEIGHT_LIST_REQUEST, WEIGHT_LIST_SUCCESS, WEIGHT_LIST_FAILURE]
        }
    }
}

export function associaPesata(barcode) {
    return {
        [CALL_API]: {
            endpoint: 'pesata',
            authenticated: true,
            method: 'POST',
            data:{'barcode':barcode},
            types: [ASSOCIA_PESATA_REQUEST, ASSOCIA_PESATA_SUCCESS, ASSOCIA_PESATA_FAILURE]
        }
    }
}

export function openAssociaPesata() {
	return{
		type: OPEN_ASSOCIAPESATA_DIALOG,
		authenticated: true
	}
}

export function closeAssociaPesata() {
	return{
		type: CLOSE_ASSOCIAPESATA_DIALOG,
		authenticated: true
	}
}

export function getWeightDetail(id) {
    return {
        [CALL_API]: {
            endpoint: 'pesata?ID='+id,
            authenticated: true,
            types: [WEIGHT_DETAIL_REQUEST, WEIGHT_DETAIL_SUCCESS, WEIGHT_DETAIL_FAILURE]
        }
    }
}

export function salvaShippingDocument(shipping) {
    return {
        [CALL_API]: {
            endpoint: 'shipping',
            authenticated: true,
            method: 'POST',
            data:shipping,
            types: [SAVE_SHIPPING_REQUEST, SAVE_SHIPPING_SUCCESS, SAVE_SHIPPING_FAILURE]
        }
    }
}


export function stampaShippingDocument(id) {
    return {
        [CALL_API]: {
            endpoint: 'shipping/stampa?ID='+id,
            authenticated: true,
            types: [PRINT_SHIPPING_REQUEST, PRINT_SHIPPING_SUCCESS, PRINT_SHIPPING_FAILURE]
        }
    }
}


export function openDettaglioPesata() {
	return{
		type: OPEN_DETTAGLIOPESATA_DIALOG,
		authenticated: true
	}
}

export function closeDettaglioPesata() {
	return{
		type: CLOSE_DETTAGLIOPESATA_DIALOG,
		authenticated: true
	}
}

export function closeMessaggioPesata() {
	return{
		type: CLOSE_MESSAGGIO_DIALOG,
		authenticated: true
	}
}

export function openEditShippingPesata() {
	return{
		type: OPEN_EDITSHIPPING_DIALOG,
		authenticated: true
	}
}

export function closeEditShippingPesata() {
	return{
		type: CLOSE_EDITSHIPPING_DIALOG,
		authenticated: true
	}
}