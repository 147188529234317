import { WEIGHT_LIST_REQUEST, WEIGHT_LIST_SUCCESS, WEIGHT_LIST_FAILURE } from '../actions';
import { CAUSALI_REQUEST, CAUSALI_SUCCESS, CAUSALI_FAILURE } from '../actions';
import { ASSOCIA_PESATA_REQUEST, ASSOCIA_PESATA_SUCCESS, ASSOCIA_PESATA_FAILURE } from '../actions';
import { OPEN_ASSOCIAPESATA_DIALOG, CLOSE_ASSOCIAPESATA_DIALOG } from '../actions';
import { WEIGHT_DETAIL_REQUEST, WEIGHT_DETAIL_SUCCESS, WEIGHT_DETAIL_FAILURE } from '../actions';
import { OPEN_DETTAGLIOPESATA_DIALOG, CLOSE_DETTAGLIOPESATA_DIALOG } from '../actions';
import { OPEN_EDITSHIPPING_DIALOG, CLOSE_EDITSHIPPING_DIALOG } from '../actions';
import { PRINT_SHIPPING_REQUEST, PRINT_SHIPPING_SUCCESS, PRINT_SHIPPING_FAILURE } from '../actions';
import { SAVE_SHIPPING_REQUEST, SAVE_SHIPPING_SUCCESS, SAVE_SHIPPING_FAILURE } from '../actions';
import { CLOSE_MESSAGGIO_DIALOG } from '../actions';

function download(strData, strFileName, strMimeType) {
    var D = document, A = arguments, a = D.createElement("a"),
         d = A[0], n = A[1], t = A[2] || "application/pdf";

    var newdata = "data:" + strMimeType + ";base64," + escape(strData);

    //build download link:
    a.href = newdata;

    if ('download' in a) {
        a.setAttribute("download", n);
        a.innerHTML = "downloading...";
        D.body.appendChild(a);
            setTimeout(function () {
                var e = D.createEvent("MouseEvents");
                e.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null
            );
            a.dispatchEvent(e);
            D.body.removeChild(a);
        }, 66);
        return true;
    };
}



// The userdata reducer
export default function weight(state = {
        isFetching: false,
        weight_list: [],
        causali:[],
        associa_error_message:'',
        associapesata_dialog_open:false,
        authenticated: false,
        pesata_error_message:'',
        messaggio_dialog_open:false,
        weight_detail:{
            Persona_Autorizzata:{},
            Shipper:{}
        }
	}, action) {
	switch (action.type) {
		case WEIGHT_LIST_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			})
        case WEIGHT_LIST_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				weight_list: action.response.data,
				authenticated: action.authenticated || false
			})
        case WEIGHT_LIST_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
            })
        case ASSOCIA_PESATA_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			})
        case ASSOCIA_PESATA_SUCCESS:
            var weightList = state.weight_list;
            var newWeight = action.response.data;
            weightList.splice(0,0,action.response.data);
            var weightProp = JSON.parse(JSON.stringify(weightList));
			return Object.assign({}, state, {
                isFetching: false,
                weight_list:weightProp,
                associa_error_message:'',
                associapesata_dialog_open:false,
				authenticated: action.authenticated || false
			})
        case ASSOCIA_PESATA_FAILURE:
            return Object.assign({}, state, {
                associa_error_message:action.error,
				isFetching: false
            })
        case CAUSALI_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			})
        case CAUSALI_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				causali: action.response.data,
				authenticated: action.authenticated || false
			})
        case CAUSALI_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
            })
        case OPEN_ASSOCIAPESATA_DIALOG:
            return Object.assign({}, state, {
                associapesata_dialog_open:true
            })
        case CLOSE_ASSOCIAPESATA_DIALOG:
            return Object.assign({}, state, {
                associa_error_message:'',
                associapesata_dialog_open:false
            })
        case WEIGHT_DETAIL_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			})
        case WEIGHT_DETAIL_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				weight_detail: action.response.data,
				authenticated: action.authenticated || false
			})
        case WEIGHT_DETAIL_FAILURE:
			return Object.assign({}, state, {
                isFetching: false,
                messaggio_dialog_open:true,
                pesata_error_message:action.error
            })
        case SAVE_SHIPPING_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			})
        case SAVE_SHIPPING_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
                messaggio_dialog_open: true,
                pesata_error_message:'',
                weight_detail:{
                    Persona_Autorizzata:{},
                    Shipper:{}
                },
                shipping_dialog_open:false,
				authenticated: action.authenticated || false
			})
        case SAVE_SHIPPING_FAILURE:
			return Object.assign({}, state, {
                isFetching: false,
                messaggio_dialog_open:true,
                shipping_dialog_open:false,
                pesata_error_message:action.error
            })
        case OPEN_DETTAGLIOPESATA_DIALOG:
            return Object.assign({}, state, {
                dettagliopesata_dialog_open:true
            })
        case CLOSE_DETTAGLIOPESATA_DIALOG:
            return Object.assign({}, state, {
                pesata_error_message:'',
                dettagliopesata_dialog_open:false
            })
        case OPEN_EDITSHIPPING_DIALOG:
            return Object.assign({}, state, {
                shipping_dialog_open:true
            })
        case CLOSE_EDITSHIPPING_DIALOG:
            return Object.assign({}, state, {
                pesata_error_message:'',
                shipping_dialog_open:false
            })
        case CLOSE_MESSAGGIO_DIALOG:
            return Object.assign({}, state, {
                pesata_error_message:'',
                messaggio_dialog_open:false
            })

        case PRINT_SHIPPING_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                messaggio_dialog_open:false,
                pesata_error_message:''
            })
        case PRINT_SHIPPING_SUCCESS:
            download(action.response.data.File,action.response.data.Nome,'application/pdf')
            return Object.assign({}, state, {
                isFetching: false,
                messaggio_dialog_open:false,
                pesata_error_message: ''
            })
        case PRINT_SHIPPING_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                messaggio_dialog_open:true,
                pesata_error_message: action.error
            })
		default:
			return state
	}
}