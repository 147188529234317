// components/Login.js

import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import LockIcon from 'material-ui-icons/Lock';
import TextField from 'material-ui/TextField';
import Button from 'material-ui/Button';
import Paper from 'material-ui/Paper';
import styles from '../css/app.css';
import logo from '../img/logo.png'
import logoAnt from '../img/logoAnt.jpg'
import { FormControl } from 'material-ui/Form';
import Input, { InputLabel } from 'material-ui/Input';
import { withStyles } from 'material-ui/styles';
import grey from 'material-ui/colors/grey';
import Grid from 'material-ui/Grid';
import RecuperoDialog from './RecuperoDialog';
import Snackbar from 'material-ui/Snackbar/Snackbar';
const stile = theme => ({
	container: {
	  display: 'flex',
	  flexWrap: 'wrap',
	},
	formControl: {
	  margin: theme.spacing.unit,
	},
	inputLabelFocused: {
	  color: 'rgb(0, 121, 191)',
	},
	inputInkbar: {
	  '&:after': {
		backgroundColor: 'rgb(0, 121, 191)',
	  },
	},
	textFieldRoot: {
	  padding: 0,
	  'label + &': {
		marginTop: theme.spacing.unit * 3,
	  },
	},
	textFieldInput: {
	  borderRadius: 4,
	  backgroundColor: theme.palette.common.white,
	  border: '1px solid #ced4da',
	  fontSize: 16,
	  padding: '10px 12px',
	  width: 'calc(100% - 24px)',
	  transition: theme.transitions.create(['border-color', 'box-shadow']),
	  '&:focus': {
		borderColor: '#80bdff',
		boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
	  },
	},
	textFieldFormLabel: {
	  fontSize: 18,
	},
  });

class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {
			username: '',
            password: '',
		};

		this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
 
    handleSubmit(e) {
        e.preventDefault();
        const { username, password } = this.state;
		//console.log(username,password)
		if (username && password) {
			const creds = { username: username.trim(), password: password.trim() }
			this.props.onLoginClick(creds)
		}
	}
	handleRecover(email){
        //debugger;
        this.props.handlePasswordRecover(email);
    }
    componentDidUpdate(){
        setTimeout(()=>{
            this.props.onClearErrors();
        },2000);
    }

    
	render() {
		const { errorMessage,classes } = this.props;
		return (
			<div className="loggedout">
                <div className="header">
                    <Grid container>
                        <Grid item xs={12} sm={6} className='logo'>
                            <img src={logo} alt="ISLogo" height="110" width="260" style={{marginRight:35}} />
                            
                            {/*<img src={logoAnt} alt="ANTLogo" height="110" width="230"/>*/}
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>
                            <div className="site-title">
                                <div>
                                    <p>SERVIZIO DI PESATURA</p>
                                </div>
                            </div>
                            
                        </Grid>
                    </Grid>
                </div>
				<div className='login-container'>
					<div>
                        <Paper className='login'>
                            <div style={{textAlign:'center',padding:"10px 0 10px 0"}}>
                                <form onSubmit={this.handleSubmit}>
                                    <section>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel
                                                FormControlClasses={{
                                                    focused: classes.inputLabelFocused,
                                                }}
                                                htmlFor="username"
                                            >
                                                Username
                                            </InputLabel>
                                            <Input
                                                onChange={this.handleChange}
                                                name="username"
                                                classes={{
                                                    underline: classes.inputInkbar,
                                                }}
                                                id="username"
                                            />
                                        </FormControl>
                                    </section>
                                    <section>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel
                                                FormControlClasses={{
                                                    focused: classes.inputLabelFocused,
                                                }}
                                                htmlFor="password"
                                            >
                                                Password
                                            </InputLabel>
                                            <Input
                                                type="password"
                                                onChange={this.handleChange}
                                                name="password"
                                                classes={{
                                                    underline: classes.inputInkbar,
                                                }}
                                                id="password"
                                            />
                                        </FormControl>
                                    
                                    </section>
                                    {errorMessage && <p style={{color: 'rgb(240, 77, 77)'}}>{errorMessage}</p>}
                                    <div className='btn-login'>
                                        <Button variant="raised" color="primary" onClick={this.handleSubmit} className="btn btn-primary">
                                            Accedi
                                        </Button>
                                    </div>
                                    <button style={{visibility: 'hidden'}} type="submit">Add</button>
                                </form>
                            </div>
                            <div className='footer-container'>
								<section>
                                    <Button color="primary" className={classes.button} onClick={this.props.onRegistrazioneClick}>
                                        Registrazione
                                    </Button>
								</section>
                                <section>
                                    <Button color="primary" className={classes.button} onClick={this.props.onRecuperoPasswordClick}>
                                        RECUPERO PASSWORD
                                    </Button>
								</section>
							</div>
						</Paper>
					</div>


                    {this.props.isRecuperoOpen==true && <div className='row login-container'>
					<div className='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4'>
						<Paper className='login'>
							<form onSubmit={(event)=>{this.handleClick(); event.preventDefault();}}>
								<section>
									<TextField ref='username' hintText="Digita qui il tuo username"  floatingLabelText='Username'/>
								</section>
								<section>
									<TextField type='email' ref='email' hintText="Digita qui la tua mail"  floatingLabelText='Email'/>
								</section>
								{errorMessage && <p style={{color: 'rgb(240, 77, 77)'}}>{errorMessage}</p>}
								<div className='btn-login'>
                                    <Button variant="raised" color="primary" onClick={this.handleSubmit} className="btn btn-primary">
                                        Recupera
                                    </Button>
								</div>
								<button style={{visibility: 'hidden'}} type="submit">Add</button>
							</form>
							<div className='footer-container'>
								<section>
                                    <Button color="primary" className={classes.button} onClick={this.props.onRegistrazioneClick}>
                                        Torna a login
                                    </Button>
								</section>
							</div>
						</Paper>
					</div>
				</div>
				}
                <RecuperoDialog open={this.props.isRecuperoOpen} handleClose={this.props.onCloseRecuperoPassword} recover={(email)=>this.handleRecover(email)} classes={classes}></RecuperoDialog>
                <Snackbar message={this.props.errorMessage} open={this.props.errorMessage}/>
                <Snackbar message={this.props.successMessage} open={this.props.successMessage}/>
				</div>
			</div>
		)
	}
}

Login.propTypes = {
	onLoginClick: PropTypes.func.isRequired,
	errorMessage: PropTypes.string
}

function mapStateToProps(state){
    //console.log(state)
    const {user} = state;
    const {isRecuperoOpen,isFetching,successMessage} = user;
    //console.log(isRecuperoOpen,user);
    return{
        isRecuperoOpen,
        isFetching,
        successMessage
    }
}

export default withStyles(stile,{name: 'Login'})(connect(mapStateToProps)(Login));