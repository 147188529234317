import { NAVBAR_TOGGLE_SWITCH,NAVBAR_NAVIGATE_TO } from '../actions';


export default function sidemenu(state = {
	expanded: false,
	selected: 'home'
	}, action) {
	switch (action.type) {
		case NAVBAR_TOGGLE_SWITCH:
			return Object.assign({}, state, {
				expanded: (typeof action.mustclose!== "undefined") ? action.mustclose : !state.expanded
			})
		case NAVBAR_NAVIGATE_TO:
			console.log('aaa',state,action);
			return Object.assign({}, state, {
				selected: action.selected
			})
		default:
			return state
	}
}

