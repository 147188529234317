// The middleware to call the API for quotes
import { CALL_API } from '../middleware/api'
import { STAMPA_TESSERA_REQUEST, STAMPA_TESSERA_SUCCESS, STAMPA_TESSERA_FAILURE } from '../actions';



export function ristampaTessera() {
    return {
        [CALL_API]: {
            endpoint: 'user/tessera',
            authenticated: true,
            types: [STAMPA_TESSERA_REQUEST, STAMPA_TESSERA_SUCCESS, STAMPA_TESSERA_FAILURE]
        }
    }
}
