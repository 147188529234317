import { combineReducers } from 'redux';

import loggeduser from './loggeduser';
import user from './user';
import sidemenu from './sidemenu';
import weight from './weight';
import userinfo from './userinfo';
import registrazione from './register';
import edituser from './edituser';
import tessera from './tessera';
import fatture from './fatture';
import prenotazioni from './prenotazioni';

const portaleAssitenzaApp = combineReducers({
	user,
	loggeduser,
    sidemenu,
    weight,
    userinfo,
    registrazione,
    edituser,
    tessera,
    fatture,
    prenotazioni
});

export default portaleAssitenzaApp