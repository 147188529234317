import { USER_DATA_REQUEST, USER_DATA_SUCCESS, USER_DATA_FAILURE } from '../actions';

// The userdata reducer
export default function loggeduser(state = {
	isFetching: false,
	userinfo: {},
	authenticated: false
	}, action) {
	switch (action.type) {
		case USER_DATA_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			})
		case USER_DATA_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				userinfo: JSON.parse(localStorage.getItem('userinfo')),
				authenticated: action.authenticated || false
			})
		case USER_DATA_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		default:
			return state
	}
}