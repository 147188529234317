// The middleware to call the API for quotes
import { CALL_API } from '../middleware/api'
import { PRENOTAZIONI_REQUEST, PRENOTAZIONI_SUCCESS, PRENOTAZIONI_FAILURE } from '../actions';
import { DELPRENOTAZIONE_REQUEST, DELPRENOTAZIONE_SUCCESS, DELPRENOTAZIONE_FAILURE } from '../actions';
import { PRENOTAZIONEDET_REQUEST, PRENOTAZIONEDET_SUCCESS, PRENOTAZIONEDET_FAILURE } from '../actions';
import { SAVEPRENOTAZIONE_REQUEST, SAVEPRENOTAZIONE_SUCCESS, SAVEPRENOTAZIONE_FAILURE } from '../actions';
import { OPEN_EDITPRENOTAZIONE_DIALOG,CLOSE_EDITPRENOTAZIONE_DIALOG } from '../actions';
import { CLOSE_EDITPRENOTAZIONEESITO_DIALOG } from '../actions';

const serialize = function (filter){
    var str = [];
    for(var p in filter)
        if (filter.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(filter[p]));
        }
    return str.join("&");
}


export function getPrenotazioni(filter) {
    return {
        [CALL_API]: {
            endpoint: 'prenotazioni?'+serialize(filter),
            authenticated: true,
            types: [PRENOTAZIONI_REQUEST, PRENOTAZIONI_SUCCESS, PRENOTAZIONI_FAILURE]
        }
    }
}

export function getPrenotazione(id) {
    console.log('get');
    return {
        [CALL_API]: {
            endpoint: 'prenotazione?id='+id,
            authenticated: true,
            types: [PRENOTAZIONEDET_REQUEST, PRENOTAZIONEDET_SUCCESS, PRENOTAZIONEDET_FAILURE]
        }
    }
}


export function deletePrenotazione(id) {
    console.log('del');
    return {
        [CALL_API]: {
            endpoint: 'prenotazione?id='+id,
            authenticated: true,
            method: 'DELETE',
            data:id,
            types: [DELPRENOTAZIONE_REQUEST, DELPRENOTAZIONE_SUCCESS, DELPRENOTAZIONE_FAILURE]
        }
    }
}

export function savePrenotazione(prenotazione) {
    console.log('save');
    return {
        [CALL_API]: {
            endpoint: 'Prenotazione',
            authenticated: true,
            method: 'POST',
            data:prenotazione,
            types: [SAVEPRENOTAZIONE_REQUEST, SAVEPRENOTAZIONE_SUCCESS, SAVEPRENOTAZIONE_FAILURE]
        }
    }
}

export function openEditPrenotazione() {
	return{
		type: OPEN_EDITPRENOTAZIONE_DIALOG,
		authenticated: true
	}
}

export function closeEditPrenotazione() {
	return{
		type: CLOSE_EDITPRENOTAZIONE_DIALOG,
		authenticated: true
	}
}

export function closeEsitoPrenotazioneDialog() {
	return{
		type: CLOSE_EDITPRENOTAZIONEESITO_DIALOG,
		authenticated: true
	}
}