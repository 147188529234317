// The middleware to call the API for quotes
import { CALL_API } from '../middleware/api'
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, RECOVERY_SUCCESS, RECOVERY_REQUEST, RECOVERY_FAILURE, CLEAR_ERRORS } from '../actions';
import { LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE } from '../actions';
import { REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE } from '../actions';
import { CLOSE_REGISTRAZIONE_DIALOG } from '../actions';
import { OPEN_RECUPERO_DIALOG,CLOSE_RECUPERO_DIALOG } from '../actions';

import { navigateTo } from '../actions/navBarAction'

function requestLogin(creds) {
	return {
		type: LOGIN_REQUEST,
		isFetching: true,
		isAuthenticated: false,
		creds
	}
}

function receiveLogin(user) {
	return {
		type: LOGIN_SUCCESS,
		isFetching: false,
		isAuthenticated: true,
		loginType:'password'
	}
}

function loginError(message) {
    console.log(message);
	return {
		type: LOGIN_FAILURE,
		isFetching: false,
		isAuthenticated: false,
		message
	}
}

// Calls the API to get a token and
// dispatches actions along the way
export function loginUser(creds,history) {
	let config = {
		method: 'POST',
		headers: { 'Content-Type':'application/x-www-form-urlencoded' },
		body: `grant_type=password&username=${creds.username}&password=${creds.password}`
	}
	return dispatch => {
		
		dispatch(requestLogin(creds))
        return fetch('https://portale.serviziopesatura.it/Service/Token', config)
        //return fetch('http://qnazzano.serviziopesatura.it/Service/Token', config)
        //return fetch('http://localhost:3005/Token', config)
		.then(response =>
			response.json().then(user => ({ user, response }))
		).then(({ user, response }) =>  {
			if (!response.ok) {
				
			return Promise.reject(user)
			} else {
				if(user.access_token!==null && typeof user.access_token!=="undefined"){
					localStorage.setItem('id_token', user.access_token);
                    dispatch(receiveLogin(user))
                    history.push("/home");
				}
				else{
					dispatch(loginError(user.message))
				}
			}
		}).catch(err => {dispatch(loginError(err.error_description)); }/*console.log("Error: ", err)*/)
	}
}

export function openRecuperoPassword() {
	return{
		type: OPEN_RECUPERO_DIALOG,
		authenticated: true
	}
}

export function closeRecuperoPassword() {
	return{
		type: CLOSE_RECUPERO_DIALOG,
		authenticated: true
	}
}
export function clearErrors(){
	return{
		type:CLEAR_ERRORS,
	}
}
export function requestPasswordRecovery(email){
	return{
		[CALL_API]: {
			endpoint: 'User/Reset?email='+email,
			authenticated: false,
			method: 'GET',
			//data:email,
			types: [RECOVERY_REQUEST, RECOVERY_SUCCESS, RECOVERY_FAILURE]
		}
	}
}

function requestLogout() {
	return {
		type: LOGOUT_REQUEST,
		isFetching: true,
		isAuthenticated: true
	}
}

function receiveLogout() {
	return {
		type: LOGOUT_SUCCESS,
		isFetching: false,
		isAuthenticated: false
	}
}


// Logs the user out
export function logoutUser() {
	return dispatch => {
		dispatch(requestLogout())
		localStorage.removeItem('id_token')
		dispatch(receiveLogout())
	}
}

export function registerUser(user) {
    return {
        [CALL_API]: {
            endpoint: 'User/Registra',
            authenticated: false,
            method: 'POST',
            data:user,
            types: [REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE]
        }
    }
}

export function closeMessaggioRegistrazione() {
	return{
		type: CLOSE_REGISTRAZIONE_DIALOG
	}
}
