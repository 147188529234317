import { REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE } from '../actions';
import { CLOSE_REGISTRAZIONE_DIALOG } from '../actions';
// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.
export default function registrazione(state = {
    isFetching: false,
    errorMessage:'',
    messageOpen:false,
	isAuthenticated: localStorage.getItem('id_token') ? true : false
	}, action) {
	switch (action.type) {
		case REGISTER_REQUEST:
			return Object.assign({}, state, {
                isFetching: true,
                messageOpen:false,
                errorMessage:''
			})
		case REGISTER_SUCCESS:
			return Object.assign({}, state, {
                isFetching: false,
                messageOpen:true,
				errorMessage: ''
			})
        case REGISTER_FAILURE:
        console.log(action);
			return Object.assign({}, state, {
                isFetching: false,
                messageOpen:true,
				errorMessage: action.error
            })
        case CLOSE_REGISTRAZIONE_DIALOG:
            return Object.assign({}, state, {
                messageOpen:false
            })
		default:
			return state
	}
}