import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, RECOVERY_REQUEST, RECOVERY_SUCCESS, RECOVERY_FAILURE,CLEAR_ERRORS } from '../actions';
import { LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE } from '../actions';
import { OPEN_RECUPERO_DIALOG, CLOSE_RECUPERO_DIALOG } from '../actions';
// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.
export default function auth(state = {
    isFetching: false,
	isRecuperoOpen: false,
	isRecuperoSuccesfull:false,
	isAuthenticated: localStorage.getItem('id_token') ? true : false,
	errorMessage:"",
	successMessage:"",
	loginType:localStorage.getItem('type') ? localStorage.getItem('type') : null
	}, action) {
	switch (action.type) {
		case LOGIN_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				isAuthenticated: false,
				user: action.creds
			})
		case LOGIN_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				isAuthenticated: true,
				errorMessage: '',
				loginType:action.loginType
			})
		case LOGIN_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				isAuthenticated: false,
				errorMessage: action.message
			})
		case LOGOUT_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				isAuthenticated: false,
            })
        
        case OPEN_RECUPERO_DIALOG:
			return Object.assign({}, state, {
				isRecuperoOpen:true
            })
        
        case CLOSE_RECUPERO_DIALOG:
			return Object.assign({}, state, {
				isRecuperoOpen:false
			})
		case RECOVERY_REQUEST:
			return Object.assign({},state, {
				isFetching:true
			})
		case RECOVERY_SUCCESS:
			return Object.assign({},state,{
				isRecuperoSuccesfull:true,
				isFetching:false,
				successMessage:"Email di recupero inviata correttamente, controlla la tua casella di posta",
			})
		case RECOVERY_FAILURE:
			return Object.assign({},state,{
				isRecuperoSuccesfull:false,
				isFetching:false,
				errorMessage:action.error
			})
		case CLEAR_ERRORS:
			return Object.assign({},state,{
				errorMessage:undefined,
				successMessage:undefined,
			})
		default:
			return state
	}
}