import { USER_INFO_REQUEST, USER_INFO_SUCCESS, USER_INFO_FAILURE } from '../actions';
// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.
export default function userinfo(state = {
	isFetching: false,
	isAuthenticated: localStorage.getItem('id_token') ? true : false,
	userdetail:{}
	}, action) {
	switch (action.type) {
		case USER_INFO_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			})
		case USER_INFO_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
                errorMessage: '',
                authenticated: action.authenticated || false,
				userdetail:action.response.data
			})
		case USER_INFO_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				errorMessage: action.message
			})
		default:
			return state
	}
}