// middleware/api.js

const BASE_URL = 'https://portale.serviziopesatura.it/Service/api/'
//const BASE_URL = 'http://localhost:63023/api/'
//const BASE_URL = 'http://qnazzano.serviziopesatura.it/Service/api/'

function callApi(endpoint, authenticated, method, data) {
	let token = localStorage.getItem('id_token') || null;
	let config = {}
	if(authenticated) {
		if(token) {
			config = {
				headers: {
                'Authorization': `Bearer ${token}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				},
				method: method
			}
		}
		else {
			config = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
				method: method
            }
			throw "No token saved!"
		}
    }
    else{
        config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: method
        }
    }
	if(method==='POST'){
		config.body=JSON.stringify(data);
	}
	console.log(BASE_URL + endpoint,config);
	return fetch(BASE_URL + endpoint, config)
		.then(response => response.json().then(json => ({ json, response }))
	).then(({ json, response }) => {
        //console.log(json,response);
		if (!response.ok || (response.ok && json.code!==200)) {
			console.log(json);
			return Promise.reject(json)
		}
		
		return method==='DELETE' ? data : json
	}).catch(err => Promise.reject(err))
}

export const CALL_API = 'Call API'

export default store => next => action => {

	const callAPI = action[CALL_API]

	// So the middleware doesn't get applied to every single action
	if (typeof callAPI === 'undefined') {
		return next(action)
	}

	let { endpoint, types, authenticated } = callAPI

	
	const [ requestType, successType, errorType ] = types;
	
	const method = callAPI.method || 'GET';
	const data = callAPI.data || {};

	const actionWith = data => {
		const finalAction = Object.assign({}, action, data)
		delete finalAction[CALL_API]
		return finalAction
	}
	
	next(actionWith({ type: requestType }))
	// Passing the authenticated boolean back in our data will let us distinguish between normal and secret quotes
	return callApi(endpoint, authenticated, method, data).then(
		response => next({
			response,
			authenticated,
			type: successType,
			params:data
		}),
		error => next({
			error: error.message || 'There was an error.',
			type: errorType
		})
	)
}