//import 'react-app-polyfill/ie11';
import React from 'react'
import { render } from 'react-dom'
import { createStore, applyMiddleware  } from 'redux'
import { Provider } from 'react-redux'
import App from './containers/App'
import portaleAssitenzaApp from './reducers/reducer'
import thunkMiddleware from 'redux-thunk'
import api from './middleware/api'
import {MuiThemeProvider,createMuiTheme}  from 'material-ui/styles';
import { Router,Route,Switch } from 'react-router'
import createBrowserHistory from 'history/createBrowserHistory'
import Registrazione from './components/Registrazione'
import GestioneUtente from './components/GestioneUtente'
import Contatti from './components/Contatti'
import Fatture from './components/Fatture'
import Prenotazioni from './components/Prenotazioni'
var moment = require('moment');

let createStoreWithMiddleware = applyMiddleware(thunkMiddleware, api)(createStore)

let store = createStoreWithMiddleware(portaleAssitenzaApp,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

let rootElement = document.getElementById('root')

const history = createBrowserHistory()

const newTheme = createMuiTheme ({
	palette: {
		primary:{
			main:'rgb(0, 121, 191)'
		}
	}
});

render(
	<Provider store={store}>
		<MuiThemeProvider theme={newTheme}>
			<Router history={history}>
				<Switch>
					<Route path="/fatture" component={Fatture}/>
					<Route path="/registrazione" component={Registrazione}/>
                    <Route path="/gestioneutente" component={GestioneUtente}/>
                    <Route path="/contatti" component={Contatti}/>
                    <Route path="/prenotazioni" component={Prenotazioni}/>
					<Route path="/" component={App}/>
				</Switch>
			</Router>
		</MuiThemeProvider>
	</Provider>,
	rootElement
)
