import React from 'react';
import Button from 'material-ui/Button';
import Dialog from 'material-ui/Dialog';
import DialogActions from 'material-ui/Dialog/DialogActions';
import DialogContent from 'material-ui/Dialog/DialogContent';
import DialogContentText from 'material-ui/Dialog/DialogContentText';
import DialogTitle from 'material-ui/Dialog/DialogTitle';
import Slide from 'material-ui/transitions/Slide';
import Input from 'material-ui/Input'
import FormControl from 'material-ui/Form/FormControl';
import InputLabel from 'material-ui/Input/InputLabel';
function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class RecuperoDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            email:'',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleRecupera = this.handleRecupera.bind(this);
    }
    
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    handleRecupera(){
        const regex = new RegExp("[a-zA-z0-9]*@[a-zA-z0-9]*.[a-z]*");
        if(regex.test(this.state.email)){
            this.props.recover(this.state.email);
        }else{

        }
        
    }
    render() {
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Recupero Password"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {/*<div style={{textAlign:'center'}}>Inserisci la tua Email</div>*/}
                            <FormControl>
                                <InputLabel
                                 FormControlClasses={{
                                    focused: this.props.classes.inputLabelFocused,
                                    }}
                                    htmlFor="email">
                                        Email
                                </InputLabel>
                                <Input
                                    type="email"
                                    onChange={this.handleChange}
                                    name="email"
                                    
                                    id="email"
                                />
                            </FormControl>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            ANNULLA
                        </Button>
                        <Button onClick={this.handleRecupera} color="primary">
                            RECUPERA
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default RecuperoDialog;
