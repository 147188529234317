// There are three possible states for our login
// process and we need actions for each of them
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'


export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'


export const USER_DATA_REQUEST = 'USER_DATA_REQUEST'
export const USER_DATA_SUCCESS = 'USER_DATA_SUCCESS'
export const USER_DATA_FAILURE = 'USER_DATA_FAILURE'

export const NAVBAR_TOGGLE_SWITCH = 'NAVBAR_TOGGLE_SWITCH'
export const NAVBAR_NAVIGATE_TO = 'NAVBAR_NAVIGATE_TO'

export const WEIGHT_LIST_REQUEST='WEIGHT_LIST_REQUEST'
export const WEIGHT_LIST_SUCCESS='WEIGHT_LIST_SUCCESS'
export const WEIGHT_LIST_FAILURE='WEIGHT_LIST_FAILURE'

export const USER_INFO_REQUEST='USER_INFO_REQUEST'
export const USER_INFO_SUCCESS='USER_INFO_SUCCESS'
export const USER_INFO_FAILURE='USER_INFO_FAILURE'

export const CAUSALI_REQUEST='CAUSALI_REQUEST'
export const CAUSALI_SUCCESS='CAUSALI_SUCCESS'
export const CAUSALI_FAILURE='CAUSALI_FAILURE'

export const ASSOCIA_PESATA_REQUEST='ASSOCIA_PESATA_REQUEST'
export const ASSOCIA_PESATA_SUCCESS='ASSOCIA_PESATA_SUCCESS'
export const ASSOCIA_PESATA_FAILURE='ASSOCIA_PESATA_FAILURE'

export const OPEN_ASSOCIAPESATA_DIALOG='OPEN_ASSOCIAPESATA_DIALOG'
export const CLOSE_ASSOCIAPESATA_DIALOG='CLOSE_ASSOCIAPESATA_DIALOG'

export const REGISTER_REQUEST='REGISTER_REQUEST'
export const REGISTER_SUCCESS='REGISTER_SUCCESS'
export const REGISTER_FAILURE='REGISTER_FAILURE'

export const RECOVERY_SUCCESS='RECOVERY_SUCCESS'
export const RECOVERY_REQUEST='RECOVERY_REQUEST'
export const RECOVERY_FAILURE='RECOVERY_FAILURE'


export const CLOSE_REGISTRAZIONE_DIALOG = 'CLOSE_REGISTRAZIONE_DIALOG'

export const STAMPA_TESSERA_REQUEST = 'STAMPA_TESSERA_REQUEST'
export const STAMPA_TESSERA_SUCCESS = 'STAMPA_TESSERA_SUCCESS'
export const STAMPA_TESSERA_FAILURE = 'STAMPA_TESSERA_FAILURE'

export const CAMBIA_PASSWORD_REQUEST = 'CAMBIA_PASSWORD_REQUEST'
export const CAMBIA_PASSWORD_SUCCESS = 'CAMBIA_PASSWORD_SUCCESS'
export const CAMBIA_PASSWORD_FAILURE = 'CAMBIA_PASSWORD_FAILURE'

export const OPEN_PASSWORD_DIALOG = 'OPEN_PASSWORD_DIALOG'
export const CLOSE_PASSWORD_DIALOG = 'CLOSE_PASSWORD_DIALOG'

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE'
export const CLOSE_EDIT_USER_DIALOG = 'CLOSE_EDIT_USER_DIALOG'


export const WEIGHT_DETAIL_REQUEST = 'WEIGHT_DETAIL_REQUEST'
export const WEIGHT_DETAIL_SUCCESS = 'WEIGHT_DETAIL_SUCCESS'
export const WEIGHT_DETAIL_FAILURE = 'WEIGHT_DETAIL_FAILURE'

export const SAVE_SHIPPING_REQUEST = 'SAVE_SHIPPING_REQUEST'
export const SAVE_SHIPPING_SUCCESS = 'SAVE_SHIPPING_SUCCESS'
export const SAVE_SHIPPING_FAILURE = 'SAVE_SHIPPING_FAILURE'

export const PRINT_SHIPPING_REQUEST = 'PRINT_SHIPPING_REQUEST'
export const PRINT_SHIPPING_SUCCESS = 'PRINT_SHIPPING_SUCCESS'
export const PRINT_SHIPPING_FAILURE = 'PRINT_SHIPPING_FAILURE'

export const OPEN_DETTAGLIOPESATA_DIALOG = 'OPEN_DETTAGLIOPESATA_DIALOG'
export const CLOSE_DETTAGLIOPESATA_DIALOG = 'CLOSE_DETTAGLIOPESATA_DIALOG'
export const OPEN_EDITSHIPPING_DIALOG = 'OPEN_EDITSHIPPING_DIALOG'
export const CLOSE_EDITSHIPPING_DIALOG = 'CLOSE_EDITSHIPPING_DIALOG'

export const CLOSE_MESSAGGIO_DIALOG = 'CLOSE_MESSAGGIO_DIALOG'

export const FATTURE_REQUEST = 'FATTURE_REQUEST'
export const FATTURE_SUCCESS = 'FATTURE_SUCCESS'
export const FATTURE_FAILURE = 'FATTURE_FAILURE'

export const PRINT_FATTURA_REQUEST = 'PRINT_FATTURA_REQUEST'
export const PRINT_FATTURA_SUCCESS = 'PRINT_FATTURA_SUCCESS'
export const PRINT_FATTURA_FAILURE = 'PRINT_FATTURA_FAILURE'

export const PRENOTAZIONI_REQUEST = 'PRENOTAZIONI_REQUEST'
export const PRENOTAZIONI_SUCCESS = 'PRENOTAZIONI_SUCCESS'
export const PRENOTAZIONI_FAILURE = 'PRENOTAZIONI_FAILURE'

export const DELPRENOTAZIONE_REQUEST = 'DELPRENOTAZIONE_REQUEST'
export const DELPRENOTAZIONE_SUCCESS = 'DELPRENOTAZIONE_SUCCESS'
export const DELPRENOTAZIONE_FAILURE = 'DELPRENOTAZIONE_FAILURE'

export const PRENOTAZIONEDET_REQUEST = 'PRENOTAZIONEDET_REQUEST'
export const PRENOTAZIONEDET_SUCCESS = 'PRENOTAZIONEDET_SUCCESS'
export const PRENOTAZIONEDET_FAILURE  = 'PRENOTAZIONEDET_FAILURE'

export const SAVEPRENOTAZIONE_REQUEST = 'SAVEPRENOTAZIONE_REQUEST'
export const SAVEPRENOTAZIONE_SUCCESS = 'SAVEPRENOTAZIONE_SUCCESS'
export const SAVEPRENOTAZIONE_FAILURE = 'SAVEPRENOTAZIONE_FAILURE'

export const OPEN_EDITPRENOTAZIONE_DIALOG = 'OPEN_EDITPRENOTAZIONE_DIALOG'
export const CLOSE_EDITPRENOTAZIONE_DIALOG = 'CLOSE_EDITPRENOTAZIONE_DIALOG'

export const CLOSE_EDITPRENOTAZIONEESITO_DIALOG = 'CLOSE_EDITPRENOTAZIONEESITO_DIALOG'

export const OPEN_RECUPERO_DIALOG = 'OPEN_RECUPERO_DIALOG'
export const CLOSE_RECUPERO_DIALOG = 'CLOSE_RECUPERO_DIALOG'