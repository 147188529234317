import { STAMPA_TESSERA_REQUEST, STAMPA_TESSERA_SUCCESS, STAMPA_TESSERA_FAILURE } from '../actions';
// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.

function download(strData, strFileName, strMimeType) {
    var D = document, A = arguments, a = D.createElement("a"),
         d = A[0], n = A[1], t = A[2] || "application/pdf";

    var newdata = "data:" + strMimeType + ";base64," + escape(strData);

    //build download link:
    a.href = newdata;

    if ('download' in a) {
        a.setAttribute("download", n);
        a.innerHTML = "downloading...";
        D.body.appendChild(a);
            setTimeout(function () {
                var e = D.createEvent("MouseEvents");
                e.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null
            );
            a.dispatchEvent(e);
            D.body.removeChild(a);
        }, 66);
        return true;
    };
}



export default function tessera(state = {
    isFetching: false,
    errorMessage:'',
    messageOpen:false,
	isAuthenticated: localStorage.getItem('id_token') ? true : false
	}, action) {
	switch (action.type) {
        case STAMPA_TESSERA_REQUEST:
        console.log('aaaaaaaaa')
			return Object.assign({}, state, {
                isFetching: true,
                messageOpen:false,
                errorMessage:''
			})
        case STAMPA_TESSERA_SUCCESS:
            download(action.response.data.File,action.response.data.Nome,'application/pdf')
			return Object.assign({}, state, {
                isFetching: false,
                messageOpen:true,
				errorMessage: ''
			})
        case STAMPA_TESSERA_FAILURE:
			return Object.assign({}, state, {
                isFetching: false,
                messageOpen:true,
				errorMessage: action.error
            })
		default:
			return state
	}
}