// containers/App.js

import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Login from '../components/Login'
import Dashboard from '../components/Dashboard'
import {loginUser,openRecuperoPassword, closeRecuperoPassword, requestPasswordRecovery,clearErrors} from '../actions/userAction'

class App extends Component {
	render() {
		const { dispatch, isAuthenticated, errorMessage, userinfo, history } = this.props
		return (
			<div>
                {
					this.props.isFetching &&
					<div className="fullBG">
						<div className="loader">
							<div></div>
						</div>
					</div>
				}
				{
					isAuthenticated && 
					<div>
						<Dashboard
							isAuthenticated={isAuthenticated}
							dispatch={dispatch}
							history={history}
						/>
						
					</div>
				}
				{
					!isAuthenticated &&
					<Login
						errorMessage={errorMessage}
                        onLoginClick={ (creds) => dispatch(loginUser(creds,history)) }
                        onRegistrazioneClick={ () => history.push('registrazione') }
						onRecuperoPasswordClick={ () => dispatch(openRecuperoPassword())}
						onCloseRecuperoPassword = { ()=> dispatch(closeRecuperoPassword())}
						handlePasswordRecover = {(email)=>{dispatch(requestPasswordRecovery(email))}}
						onClearErrors = {()=>{dispatch(clearErrors())}}
					/>
				}
			</div>
		)
	}
}

App.propTypes = {
	dispatch: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
	errorMessage: PropTypes.string,
	userinfo: PropTypes.object.isRequired,
}

// These props come from the application's
// state when it is started
function mapStateToProps(state) {
	const { user, loggeduser } = state
	const { userinfo } = loggeduser
	const { isFetching, isAuthenticated, errorMessage, isRecuperoOpen  } = user
	return {
		isAuthenticated,
		errorMessage,
        userinfo,
        isFetching,
        isRecuperoOpen
	}
}

export default connect(mapStateToProps)(App)