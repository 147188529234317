// The middleware to call the API for quotes
import { CALL_API } from '../middleware/api'
import { FATTURE_REQUEST, FATTURE_SUCCESS, FATTURE_FAILURE } from '../actions';
import { PRINT_FATTURA_REQUEST, PRINT_FATTURA_SUCCESS, PRINT_FATTURA_FAILURE } from '../actions';

const serialize = function (filter){
    var str = [];
    for(var p in filter)
        if (filter.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(filter[p]));
        }
    return str.join("&");
}


export function getFatture(filter) {
    return {
        [CALL_API]: {
            endpoint: 'fatture?'+serialize(filter),
            authenticated: true,
            types: [FATTURE_REQUEST, FATTURE_SUCCESS, FATTURE_FAILURE]
        }
    }
}

export function stampaFattura(id) {
    console.log('here');
    return {
        [CALL_API]: {
            endpoint: 'fattura/stampa?ID='+id,
            authenticated: true,
            types: [PRINT_FATTURA_REQUEST, PRINT_FATTURA_SUCCESS, PRINT_FATTURA_FAILURE]
        }
    }
}

