// components/Login.js

import React, { Component } from 'react'
import PropTypes from 'prop-types';
import LockIcon from 'material-ui-icons/Lock';
import TextField from 'material-ui/TextField';
import Button from 'material-ui/Button';
import Paper from 'material-ui/Paper';
import styles from '../css/app.css'
import styled from 'styled-components';
import CustomSideNav from './CustomSideNav'
import { connect } from 'react-redux'
import logo from '../img/logo.png'
import purple from 'material-ui/colors/purple';
import { withStyles } from 'material-ui/styles';
import ReactDataGrid from 'react-data-grid';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../css/react_dates_overrides.css';
import { getWeightList,associaPesata,openAssociaPesata,closeAssociaPesata,getWeightDetail,openDettaglioPesata,closeDettaglioPesata,openEditShippingPesata,closeEditShippingPesata,salvaShippingDocument,closeMessaggioPesata,stampaShippingDocument }  from '../actions/weightAction';
import { getCausali }  from '../actions/causaliAction';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import { MenuItem } from 'material-ui/Menu';
import Select from 'material-ui/Select';
import Input, { InputLabel } from 'material-ui/Input';
import { FormControl, FormHelperText } from 'material-ui/Form';
import Tooltip from 'material-ui/Tooltip';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from 'material-ui/Dialog';
import Grid from 'material-ui/Grid';



import ContentAdd from 'material-ui-icons/Add';
import ContentDetail from 'material-ui-icons/Search';
import ContentPrintLabel from 'material-ui-icons/Print';
import ContentBoat from 'material-ui-icons/DirectionsBoat';
import DoneIcon from 'material-ui-icons/Done';

var moment = require('moment');
require('moment/locale/it');

function isEmpty(obj) {

    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0)    return false;
    if (obj.length === 0)  return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}

class DateFormatter extends React.Component {
    static propTypes = {
      value: PropTypes.string.isRequired
    };
  
    render() {
      return (
        <div>
            { new moment(this.props.value).format("D/M/YYYY hh:mm") }
        </div>);
    }
  }

  class DoneFormatter extends React.Component {

    static propTypes = {
        value: PropTypes.bool
    };
    
    render() {
      return (
        <div style={{marginTop:6,marginLeft:5}}>
          { typeof this.props.value !=='undefined'&&this.props.value==true ? <DoneIcon/> : '' }
        </div>);
      }
    }
//Columns definition
var columns = [
    {
        key: '',
        name: '',
        width: 15
    },
    {
      key: 'NumBolla',
      name: 'Num. Bolla',
      width: 80
    },
    {
        key: 'Data',
        name: 'Data',
        formatter : DateFormatter,
        width: 175
      },
    {
      key: 'Targa',
      name: 'Targa',
      width: 150
    },
    {
      key: 'Causale',
      name: 'Causale',
      width: 250
    },
    {
      key: 'PrimoPeso',
      name: 'Primo Peso',
      sortable : true,
      width: 125,
      resizable : true
    },
    {
        key: 'SecondoPeso',
        name: 'Secondo Peso',
        width: 125
    },
    {
        key: 'Netto',
        name: 'Netto',
        width: 125
    },
    {
        key: 'NContainer',
        name: 'Num. Container',
        width: 150
    },
    {
        key: 'Fatturata',
        name: 'Fatturata',
        width: 150,
        formatter : DoneFormatter
    }
];

const Main = styled.main`
    position: relative;
    overflow: hidden;
    transition: all .15s;
    padding: 0 0px;
    z-index: 1007;
    display:block;
    margin-left: ${props => (props.expanded ? 230 : 55)}px;
`;

const stile = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
    },
    inputLabelFocused: {
        color: 'rgb(0, 121, 191)',
      },
      inputInkbar: {
        '&:after': {
          backgroundColor: 'rgb(0, 121, 191)',
        },
      },
    textFieldRoot: {
        padding: 0,
        'label + &': {
            marginTop: theme.spacing.unit * 3,
        },
    },
    inputRoot:{
        padding: '8px 8px 10px 8px',
        border: '1px solid #dbdbdb',
        '&:after':{
            height:0
        },
        '&:before':{
            height:0
        }
    },
    dialogRoot:{
        'min-width': 875,
       
    },
    buttonRoot:{
        marginRight:20
    },
    selectMenu:{
        overflow: 'inherit'
    },
    textFieldInput: {
        borderRadius: 3,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '9px 12px',
        margin:'10px',
        width: 'calc(100% - 24px)',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: '#989898',
            boxShadow: '0 0 0 0.1rem rgba(101,101,101,.25)',
        },
    },
    textFieldFormLabel: {
        fontSize: 18,
    },
});

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
			selectedRow:null,
			selectedRows:null,
			rows:null,
            selectedIndexes:[],
            da:moment().subtract(1, 'months'),
            a:moment(),
            causale:'null',
            barcode:'',
            weightdetail_dialog_open:false,
            weight_detail:{
                Persona_Autorizzata:{},
                Shipper:{}
            },
            errors:{},
            errorsText:{},
            focusedDa:null,
            focusedA:null
        };
        
        this.handleChange = this.handleChange.bind(this);
        this.handlePAutChange = this.handlePAutChange.bind(this);
        this.handleShipChange = this.handleShipChange.bind(this);
        this.handleSaveShipping = this.handleSaveShipping.bind(this);
        this.handleWeightChange = this.handleWeightChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
        

    }

    /*shippingValidation(shipping) {
        var errors={};
        var errorsText={};
		var retVal=true;
        console.log(user);
        
		if(!user.Nome || user.Nome.length===0){
			errors['nome']= true ;
			retVal=false;
		}
        if(!user.Cognome || user.Cognome.length===0){
			errors['cognome']= true;
			retVal=false;
        }
        if(!user.Email || user.Email.length===0){
			errors['email']= true;
			retVal=false;
        }
        if(!user.Cellulare || user.Cellulare.length===0){
			errors['cellulare']= true;
			retVal=false;
        }
        if(!user.UserName || user.UserName.length===0){
			errors['username'] = true;
			retVal=false;
        }
        if(!user.Password || user.Password.length===0){
            errors['password'] = true;
            errorsText['password']="Campo Password obbligatorio";
			retVal=false;
        }
        else{
            errors['password'] = false;
        }
        if(!user.ConfermaPassword || user.ConfermaPassword.length===0){
            errors['confermapassword']= true;
            errorsText['confermapassword']="Campo Conferma Password obbligatorio";
			retVal=false;
        }
        else{
            errors['confermapassword']= false;
        }
        if((errors['password'] == false && errors['confermapassword'] == false)&&( user.Password!=user.ConfermaPassword)){
            errors['confermapassword']= true;
            errors['password'] = true;
            errorsText['password']="Password e Conferma devono coincidere";
            errorsText['confermapassword']="Password e Conferma devono coincidere";
        }
        if(!user.RagioneSociale || user.RagioneSociale.length===0){
			errors['ragionesociale']= true;
			retVal=false;
        }

  
        if(!user.Piva || user.Piva.length===0){
			errors['piva']= true;
			retVal=false;
        }
        if(!user.Telefono || user.Telefono.length===0){
			errors['telefono']= true;
			retVal=false;
        }
        if(!user.PEC || user.PEC.length===0){
			errors['pec']= true;
			retVal=false;
        }
        if(!user.Indirizzo || user.Indirizzo.length===0){
			errors['indirizzo']= true;
			retVal=false;
        }
        if(!user.Provincia || user.Provincia.length===0){
			errors['provincia']= true;
			retVal=false;
        }
        if(!user.CAP || user.CAP.length===0){
			errors['cap']= true;
			retVal=false;
        }
        if(!user.Localita || user.Localita.length===0){
			errors['localita']= true;
			retVal=false;
        }
        if(!user.Paese || user.Paese.length===0){
			errors['paese']= true;
			retVal=false;
        }
		this.setState({errors: errors,errorsText:errorsText});
		return retVal;
	}*/

    componentWillUnmount() {
        this.setState({open: false});
    }

    componentDidMount(){
        //this.props.dispatch(getWeightList({da:start.format('YYYY-MM-DDT00:00:00'),a:now.add(1, 'days').format('YYYY-MM-DDT00:00:00')}));
        this.props.dispatch(getWeightList({da:this.state.da.format('YYYY-MM-DD'),a:moment(this.state.a).add(1, 'days').format('YYYY-MM-DD'),tipoPesata:this.state.causale}));
        this.props.dispatch(getCausali());
        
    }

    handleSaveShipping(){
        this.props.dispatch(salvaShippingDocument(this.state.weight_detail));
    }

    handleGridSort = (sortColumn, sortDirection) => {
		var comparer = function(a, b) {
	      if(sortDirection === 'ASC'){
	        return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
	      }else if(sortDirection === 'DESC'){
	        return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
	      }
	    }
	    var rows_app = this.state.rows === null ? this.props.weight_list.slice(0) : this.state.rows;
	    var rows = sortDirection === 'NONE' ? this.props.weight_list.slice(0) : rows_app.sort(comparer);
	    this.setState({rows : rows,selectedRow:null,selectedRows:null,selectedIndexes:[]});
    };
    
    rowGetter = (i) => {
        return this.state.rows!==null ? this.state.rows[i] : this.props.weight_list[i];
    };
  
    onRowsSelected = (rows) => {
        /*if(rowNum.length!=0){
            this.setState({selectedRow: this.props.weight_list[rowNum],selectedRows:rowNum});
        }
        else{
            this.setState({selectedRow: null,selectedRows:null});
        }*/
        var rowNum = rows.map(r => r.rowIdx)[0];
        this.setState({selectedRow: rows[0].row,selectedRows:rowNum,selectedIndexes: rows.map(r => r.rowIdx)});
    };
  
    onRowsDeselected = (rows) => {
        this.setState({selectedRow: null,selectedRows:null,selectedIndexes: []});
    };

    onFocusChange({ focused }) { 
        this.setState({ focusedDa:focused }); 
    } 
        

    onRowClick = (rowIdx, row) => {
        if(rowIdx===this.state.selectedRows){
            this.setState({selectedRow: null,selectedRows:null,selectedIndexes: []});
        }
        else{
            this.setState({selectedRow: row,selectedRows:rowIdx,selectedIndexes: [rowIdx]});
        }

        /* rows[rowIdx] = Object.assign({}, row, {isSelected: !row.isSelected});
        this.setState({rows});*/
    }

    handleOpenAggiungiPesataDialog = () => {
        this.props.dispatch(openAssociaPesata());
        this.setState({ barcode: '' });
    };
    
    handleCloseAggiungiPesataDialog = () => {
        this.props.dispatch(closeAssociaPesata());
    };

    updateWeightList=(filter)=>{
        this.props.dispatch(getWeightList(filter));
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.weight_detail !== this.props.weight_detail) {
            var weight=nextProps.weight_detail;
            if(nextProps.weight_detail!==null){
                if(weight.Shipper==null)
                    weight.Shipper={}
                if(weight.Persona_Autorizzata==null)
                    weight.Persona_Autorizzata={}
            }
            this.setState({ weight_detail: weight })
        }
        if (nextProps.weight_detail !== this.state.weight_detail) {
            var weight=nextProps.weight_detail;
            if(nextProps.weight_detail!==null){
                if(weight.Shipper==null)
                    weight.Shipper={}
                if(weight.Persona_Autorizzata==null)
                    weight.Persona_Autorizzata={}
            }
            this.setState({ weight_detail: weight })
        }

        if(this.state.selectedRow!==null){
            if(JSON.stringify(nextProps.weight_list)!=JSON.stringify(this.props.weight_list)){
                var i = 0;
                for(var i=0;i<nextProps.weight_list.length;i++){
                    if(this.state.selectedRow.ID==nextProps.weight_list[i].ID){
                        this.setState({ selectedRow: nextProps.weight_list[i],selectedRows:i,selectedIndexes: [i] })
                    }
                }
            }
        }
        
        

        if (nextProps.shipping_dialog_open==false && this.props.shipping_dialog_open==true) {
            this.props.dispatch(getWeightList({da:this.state.da.format('YYYY-MM-DD'),a:moment(this.state.a).add(1, 'days').format('YYYY-MM-DD'),tipoPesata:this.state.causale}));
        }
        if (nextProps.associapesata_dialog_open==false && this.props.associapesata_dialog_open==true) {
            this.props.dispatch(getWeightList({da:this.state.da.format('YYYY-MM-DD'),a:moment(this.state.a).add(1, 'days').format('YYYY-MM-DD'),tipoPesata:this.state.causale}));
        }
        
        
    }
    /*componentWillReceiveProps = (nextProps) => {
        console.log('proooops');
        if(nextProps.expanded!==this.props.expanded){
            console.log('DIVERSIIIIIIIIIIIIIIII');
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
                //document.getElementsByClassName("react-grid-Canvas")[0].click()
            }, 50);
        }
        
    *if (nextProps === this.props) {
            return;
        }
    }*/

    componentDidUpdate = () => {
        setTimeout(() => {

            if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
                var evt = document.createEvent('UIEvents');
                evt.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(evt);
               } else {
                  window.dispatchEvent(new Event('resize'));
           
               }
            //window.dispatchEvent(new Event('resize'));
            //document.getElementsByClassName("react-grid-Canvas")[0].click()
        }, 50);
        
    }

    handleShipChange = (e,elem,val)=>{
        const { name, value } = e.target;
        var shipping = this.state.weight_detail;
        console.log(this.state);
        shipping['Shipper'][name]=value
        this.setState({ weight_detail: shipping });
    }
    
    handlePAutChange = (e,elem,val)=>{
        const { name, value } = e.target;
        var paut = this.state.weight_detail;
        paut['Persona_Autorizzata'][name]=value
        this.setState({ Persona_Autorizzata: paut });
    }

    handleWeightChange = (e,elem,val)=>{
        const { name, value } = e.target;
        var weight = this.state.weight_detail;
        weight[name]=value
        this.setState({ Persona_Autorizzata: weight });
    }

    handleChange = (e,elem,val)=>{
        const { name, value } = e.target;
        this.setState({ [name]: value });
        if(name=="causale")
            this.updateWeightList({da:this.state.da.format('YYYY-MM-DD'),a:moment(this.state.a).add(1, 'days').format('YYYY-MM-DD'),tipoPesata:value});
    }
    
    isButtonDisabled = () => {
		return this.state.selectedRow===null;
    };

    isButtonShippingDisabled = () => {
        console.log(this.state.selectedRow);
		return ((this.state.selectedRow===null)||(this.state.selectedRow!==null && !this.state.selectedRow.Modificabile ));
    };
    
    isButtonPrintDisabled = () => {
        console.log(this.state.selectedRow);
		return ((this.state.selectedRow===null)||(this.state.selectedRow!==null && !this.state.selectedRow.Stampa ));
    };

    handleConfirm = ()=>{
        this.props.dispatch(associaPesata(this.state.barcode));
    }

    handleDetailPesataClick = ()=> {
        this.props.dispatch(getWeightDetail(this.state.selectedRow.ID));
        this.props.dispatch(openDettaglioPesata()); 
    }

    handleCloseDettaglioPesataDialog = () => {
        this.props.dispatch(closeDettaglioPesata());
    };
    
    handleOpenShipping = ()=> {
        this.props.dispatch(getWeightDetail(this.state.selectedRow.ID));
        this.props.dispatch(openEditShippingPesata());
        
    }

    handleCloseMessaggioDialog = () => {
        this.props.dispatch(closeMessaggioPesata());
        /*this.setState({weight_detail:{
            Persona_Autorizzata:{},
            Shipper:{}
        }});*/
    };
    
    handlePrintClick = () => {
        this.props.dispatch(stampaShippingDocument(this.state.selectedRow.ID));
    };
    

    handleCloseShippingDialog = () => {
        this.props.dispatch(closeEditShippingPesata());
    };
    

    render() {
        const { classes } = this.props;
        const { errorMessage,dispatch,expanded,history,selected,causali,associapesata_dialog_open,associa_error_message,dettagliopesata_dialog_open,shipping_dialog_open,messaggio_dialog_open,pesata_error_message } = this.props;

        var itemscausali=[];
        

		if(causali.length>0){
            itemscausali.push(<MenuItem key="0" value="null">Tutte</MenuItem>);
            causali.forEach(item => itemscausali.push(<MenuItem key={item.ID} value={item.ID}>{item.Descrizione}</MenuItem>));
        }
       

        return (
            
            <div>
                {
					this.props.isFetching &&
					<div className="fullBG">
						<div className="loader">
							<div></div>
						</div>
					</div>
				}
                <CustomSideNav
                    dispatch={dispatch}
                    errorMessage={errorMessage}
                    history={history}
                    selected={selected}
                    expanded={expanded}
                />
                <Main expanded={expanded}>
                    <div className="header">
                        <div className="header-content clearfix">
                            <div className="page-title">
                                <p>Pesate</p>
                            </div>
                            <div className="site-title">
                                <div>
                                    <p>PESA PUBBLICA</p>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <Dialog
                        open={messaggio_dialog_open}
                        onClose={this.handleCloseMessaggioDialog}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            Pesate
                        </DialogTitle>
                        <DialogContent>
                            {pesata_error_message.length>0&&
                                <DialogContentText>
                                    <p className="error-text">{pesata_error_message}</p>
                                </DialogContentText>
                            }
                            {pesata_error_message.length==0&&
                                <DialogContentText>
                                    <p>Pesata aggiornata correttamente</p>
                                    <p>I dati sono stati registrati</p>
                                </DialogContentText>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseMessaggioDialog} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={dettagliopesata_dialog_open}
                        onClose={this.handleCloseDettaglioPesataDialog}
                        aria-labelledby="form-dialog-title"
                        classes={{
                            paper: classes.dialogRoot 
                        }}
                    >
                        <DialogTitle id="form-dialog-title">
                            Dettaglio Pesata
                        </DialogTitle>
                        <DialogContent
                        >
                            <div className="shipping-container">
                                <Grid container spacing={24}>
                                    <Grid item xs={12}  sm={6}>
                                        <h2>Dati pesata</h2>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['targa']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['targa']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['targa']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="targa"
                                            >
                                                Targa
                                            </InputLabel>
                                            <Input
                                                onChange={this.handlePrenChange}
                                                name="Targa"
                                                classes={{
                                                    underline: this.state.errors['targa']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.weight_detail.Targa}
                                                id="targa"
                                            />
                                            {this.state.errors['targa']===true && <FormHelperText id="name-error-text">Campo Tara Mezzo obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['nbooking']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['taramezzo']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['taramezzo']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="taramezzo"
                                            >
                                                Data
                                            </InputLabel>
                                            <Input
                                                onChange={this.handlePrenChange}
                                                name="TaraMezzo"
                                                classes={{
                                                    underline: this.state.errors['taramezzo']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={new moment(this.state.weight_detail.Data).format("D/M/YYYY hh:mm")}
                                                id="taramezzo"
                                            />
                                            {this.state.errors['taramezzo']===true && <FormHelperText id="name-error-text">Campo Tara Mezzo obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['causale']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['causale']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['causale']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="causale"
                                            >
                                                Causale
                                            </InputLabel>
                                            <Input
                                                onChange={this.handlePrenChange}
                                                name="Causale"
                                                classes={{
                                                    underline: this.state.errors['causale']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.weight_detail.Causale}
                                                id="causale"
                                            />
                                            {this.state.errors['causale']===true && <FormHelperText id="name-error-text">Campo causale obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['primopeso']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['primopeso']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['primopeso']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="primopeso"
                                            >
                                                Primo Peso
                                            </InputLabel>
                                            <Input
                                                onChange={this.handlePrenChange}
                                                name="primopeso"
                                                classes={{
                                                    underline: this.state.errors['primopeso']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.weight_detail.PrimoPeso}
                                                id="primopeso"
                                            />
                                            {this.state.errors['primopeso']===true && <FormHelperText id="name-error-text">Campo Prodotto obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['secondopeso']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['secondopeso']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['secondopeso']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="secondopeso"
                                            >
                                                Secondo Peso
                                            </InputLabel>
                                            <Input
                                                onChange={this.handlePrenChange}
                                                name="secondopeso"
                                                classes={{
                                                    underline: this.state.errors['secondopeso']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.weight_detail.SecondoPeso}
                                                id="secondopeso"
                                            />
                                            {this.state.errors['secondopeso']===true && <FormHelperText id="name-error-text">Campo Prodotto obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['netto']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['netto']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['netto']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="netto"
                                            >
                                                Peso Netto
                                            </InputLabel>
                                            <Input
                                                onChange={this.handlePrenChange}
                                                name="Netto"
                                                classes={{
                                                    underline: this.state.errors['netto']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.weight_detail.Netto}
                                                id="netto"
                                            />
                                            {this.state.errors['netto']===true && <FormHelperText id="name-error-text">Campo Prodotto obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['matricola']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['matricola']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['matricola']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="matricola"
                                            >
                                                Matricola
                                            </InputLabel>
                                            <Input
                                                onChange={this.handlePrenChange}
                                                name="Matricola"
                                                classes={{
                                                    underline: this.state.errors['matricola']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.weight_detail.Matricola}
                                                id="matricola"
                                            />
                                            {this.state.errors['matricola']===true && <FormHelperText id="name-error-text">Campo Matricola obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['ncontainer']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['ncontainer']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['ncontainer']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="ncontainer"
                                            >
                                                Num. Container
                                            </InputLabel>
                                            <Input
                                                onChange={this.handlePrenChange}
                                                name="NContainer"
                                                classes={{
                                                    underline: this.state.errors['ncontainer']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.weight_detail.NContainer}
                                                id="ncontainer"
                                            />
                                            {this.state.errors['ncontainer']===true && <FormHelperText id="name-error-text">Campo ncontainer obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['nbooking']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['nbooking']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['nbooking']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="nbooking"
                                            >
                                                Num. booking
                                            </InputLabel>
                                            <Input
                                                onChange={this.handlePrenChange}
                                                name="NBooking"
                                                classes={{
                                                    underline: this.state.errors['nbooking']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.weight_detail.NBooking}
                                                id="nbooking"
                                            />
                                            {this.state.errors['nbooking']===true && <FormHelperText id="name-error-text">Campo nbooking obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {isEmpty(this.state.weight_detail.Shipper)==false && <div>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12}  sm={6}>
                                            <h2>Shipper</h2>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12}  sm={4}>
                                            <FormControl className={classes.formControl} error={this.state.errors['shipper_nom']===true} >
                                                <InputLabel
                                                    shrink={true}
                                                    FormControlClasses={{
                                                        root: this.state.errors['shipper_nom']!==true ? classes.inputLabelFocused : '',
                                                        focused: this.state.errors['shipper_nom']!==true ? classes.inputLabelFocused : '',
                                                    }}
                                                    htmlFor="shipper_nom"
                                                >
                                                    Nominativo
                                                </InputLabel>
                                                <Input
                                                    onChange={this.handlePrenChange}
                                                    name="Nominativo"
                                                    classes={{
                                                        underline: this.state.errors['shipper_nom']!==true ? classes.inputInkbar : '',
                                                    }}
                                                    value={this.state.weight_detail.Shipper.Nominativo}
                                                    id="shipper_nom"
                                                />
                                                {this.state.errors['shipper_nom']===true && <FormHelperText id="name-error-text">Campo Nominativo obbligatorio</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}  sm={4}>
                                            <FormControl className={classes.formControl} error={this.state.errors['shipper_email']===true}>
                                                <InputLabel
                                                    shrink={true}
                                                    FormControlClasses={{
                                                        root: this.state.errors['shipper_email']!==true ? classes.inputLabelFocused : '',
                                                        focused: this.state.errors['shipper_email']!==true ? classes.inputLabelFocused : '',
                                                    }}
                                                    htmlFor="shipper_email"
                                                >
                                                    Email
                                                </InputLabel>
                                                <Input
                                                    onChange={this.handlePrenChange}
                                                    name="Email"
                                                    value={this.state.weight_detail.Shipper.Email}
                                                    classes={{
                                                        underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                                    }}
                                                    id="shipper_email"
                                                />
                                                {this.state.errors['shipper_email']===true && <FormHelperText id="name-error-text">Campo Email obbligatorio</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}  sm={4}>
                                            <FormControl className={classes.formControl} error={this.state.errors['shipper_telefono']===true}>
                                                <InputLabel
                                                    shrink={true}
                                                    FormControlClasses={{
                                                        root: this.state.errors['shipper_telefono']!==true ? classes.inputLabelFocused : '',
                                                        focused: this.state.errors['shipper_telefono']!==true ? classes.inputLabelFocused : '',
                                                    }}
                                                    htmlFor="shipper_telefono"
                                                >
                                                    Telefono
                                                </InputLabel>
                                                <Input
                                                    onChange={this.handlePrenChange}
                                                    name="Telefono"
                                                    value={this.state.weight_detail.Shipper.Telefono}
                                                    classes={{
                                                        underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                                    }}
                                                    id="shipper_telefono"
                                                />
                                                {this.state.errors['shipper_telefono']===true && <FormHelperText id="name-error-text">Campo Telefono obbligatorio</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>}
                                {isEmpty(this.state.weight_detail.Persona_Autorizzata)==false && <div>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12}  sm={6}>
                                            <h2>Persona Autorizzata</h2>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12}  sm={4}>
                                            <FormControl className={classes.formControl} error={this.state.errors['pautorizzata_nom']===true} >
                                                <InputLabel
                                                    shrink={true}
                                                    FormControlClasses={{
                                                        root: this.state.errors['pautorizzata_nom']!==true ? classes.inputLabelFocused : '',
                                                        focused: this.state.errors['pautorizzata_nom']!==true ? classes.inputLabelFocused : '',
                                                    }}
                                                    htmlFor="pautorizzata_nom"
                                                >
                                                    Nominativo
                                                </InputLabel>
                                                <Input
                                                    onChange={this.handlePrenChange}
                                                    name="Nominativo"
                                                    classes={{
                                                        underline: this.state.errors['pautorizzata_nom']!==true ? classes.inputInkbar : '',
                                                    }}
                                                    value={this.state.weight_detail.Persona_Autorizzata.Nominativo}
                                                    id="pautorizzata_nom"
                                                />
                                                {this.state.errors['pautorizzata_nom']===true && <FormHelperText id="name-error-text">Campo Nominativo obbligatorio</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}  sm={4}>
                                            <FormControl className={classes.formControl} error={this.state.errors['pautorizzata_email']===true}>
                                                <InputLabel
                                                    shrink={true}
                                                    FormControlClasses={{
                                                        root: this.state.errors['pautorizzata_email']!==true ? classes.inputLabelFocused : '',
                                                        focused: this.state.errors['pautorizzata_email']!==true ? classes.inputLabelFocused : '',
                                                    }}
                                                    htmlFor="pautorizzata_email"
                                                >
                                                    Email
                                                </InputLabel>
                                                <Input
                                                    onChange={this.handlePrenChange}
                                                    name="Email"
                                                    value={this.state.weight_detail.Persona_Autorizzata.Email}
                                                    classes={{
                                                        underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                                    }}
                                                    id="pautorizzata_email"
                                                />
                                                {this.state.errors['pautorizzata_email']===true && <FormHelperText id="name-error-text">Campo Email obbligatorio</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}  sm={4}>
                                            <FormControl className={classes.formControl} error={this.state.errors['pautorizzata_telefono']===true}>
                                                <InputLabel
                                                    shrink={true}
                                                    FormControlClasses={{
                                                        root: this.state.errors['pautorizzata_telefono']!==true ? classes.inputLabelFocused : '',
                                                        focused: this.state.errors['pautorizzata_telefono']!==true ? classes.inputLabelFocused : '',
                                                    }}
                                                    htmlFor="pautorizzata_telefono"
                                                >
                                                    Telefono
                                                </InputLabel>
                                                <Input
                                                    onChange={this.handlePrenChange}
                                                    name="Telefono"
                                                    value={this.state.weight_detail.Persona_Autorizzata.Telefono}
                                                    classes={{
                                                        underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                                    }}
                                                    id="pautorizzata_telefono"
                                                />
                                                {this.state.errors['pautorizzata_telefono']===true && <FormHelperText id="name-error-text">Campo Telefono obbligatorio</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDettaglioPesataDialog} color="primary">
                                Chiudi
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={associapesata_dialog_open}
                        onClose={this.handleCloseAggiungiPesataDialog}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            Associa Pesata
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Per associare una pesata effettuata presso la pesa digitare il barcode riportato sul bindello.
                            </DialogContentText>
                            <TextField
                                onChange={this.handleChange}
                                name="barcode"
                                autoFocus
                                margin="dense"
                                label="Barcode"
                                fullWidth
                            />
                            <div className="error-message-container">
                                {associa_error_message}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseAggiungiPesataDialog} color="primary">
                                Annulla
                            </Button>
                            <Button onClick={this.handleConfirm} color="primary">
                                Conferma
                            </Button>
                        </DialogActions>
                    </Dialog>

                  
                    <Dialog
                        open={shipping_dialog_open}
                        onClose={this.handleCloseShippingDialog}
                        aria-labelledby="form-dialog-title"
                        classes={{
                            paper: classes.dialogRoot 
                        }}
                    >
                        <DialogTitle id="form-dialog-title">
                            Shipping Document
                        </DialogTitle>
                        <DialogContent
                        >
                            <DialogContentText>
                                Per generare lo shipping document completare i dati mancanti.
                            </DialogContentText>
                            <div className="shipping-container">
                                <Grid container spacing={24}>
                                    <Grid item xs={12}  sm={6}>
                                        <h2>Booking</h2>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}  sm={6}>
                                        <FormControl className={classes.formControl} error={this.state.errors['nbooking']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['nbooking']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['nbooking']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="nbooking"
                                            >
                                                Num. Booking
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire Num. Booking"
                                                onChange={this.handleWeightChange}
                                                name="NBooking"
                                                classes={{
                                                    underline: this.state.errors['nbooking']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.weight_detail.NBooking}
                                                id="nbooking"
                                            />
                                            {this.state.errors['nbooking']===true && <FormHelperText id="name-error-text">Campo NBooking obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}  sm={6}>
                                        <h2>Shipper</h2>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['shipper_nom']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['shipper_nom']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['shipper_nom']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="shipper_nom"
                                            >
                                                Nominativo
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire il nominativo"
                                                onChange={this.handleShipChange}
                                                name="Nominativo"
                                                classes={{
                                                    underline: this.state.errors['shipper_nom']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.weight_detail.Shipper.Nominativo}
                                                id="shipper_nom"
                                            />
                                            {this.state.errors['shipper_nom']===true && <FormHelperText id="name-error-text">Campo Nominativo obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['shipper_email']===true}>
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['shipper_email']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['shipper_email']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="shipper_email"
                                            >
                                                Email
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire la mail"
                                                onChange={this.handleShipChange}
                                                name="Email"
                                                value={this.state.weight_detail.Shipper.Email}
                                                classes={{
                                                    underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                                }}
                                                id="shipper_email"
                                            />
                                            {this.state.errors['shipper_email']===true && <FormHelperText id="name-error-text">Campo Email obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['shipper_telefono']===true}>
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['shipper_telefono']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['shipper_telefono']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="shipper_telefono"
                                            >
                                                Telefono
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire numero di telefono"
                                                onChange={this.handleShipChange}
                                                name="Telefono"
                                                value={this.state.weight_detail.Shipper.Telefono}
                                                classes={{
                                                    underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                                }}
                                                id="shipper_telefono"
                                            />
                                            {this.state.errors['shipper_telefono']===true && <FormHelperText id="name-error-text">Campo Telefono obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}  sm={6}>
                                        <h2>Persona Autorizzata</h2>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['pautorizzata_nom']===true} >
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['pautorizzata_nom']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['pautorizzata_nom']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="pautorizzata_nom"
                                            >
                                                Nominativo
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire il nominativo"
                                                onChange={this.handlePAutChange}
                                                name="Nominativo"
                                                classes={{
                                                    underline: this.state.errors['pautorizzata_nom']!==true ? classes.inputInkbar : '',
                                                }}
                                                value={this.state.weight_detail.Persona_Autorizzata.Nominativo}
                                                id="pautorizzata_nom"
                                            />
                                            {this.state.errors['pautorizzata_nom']===true && <FormHelperText id="name-error-text">Campo Nominativo obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['pautorizzata_email']===true}>
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['pautorizzata_email']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['pautorizzata_email']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="pautorizzata_email"
                                            >
                                                Email
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire la mail"
                                                onChange={this.handlePAutChange}
                                                name="Email"
                                                value={this.state.weight_detail.Persona_Autorizzata.Email}
                                                classes={{
                                                    underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                                }}
                                                id="pautorizzata_email"
                                            />
                                            {this.state.errors['pautorizzata_email']===true && <FormHelperText id="name-error-text">Campo Email obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  sm={4}>
                                        <FormControl className={classes.formControl} error={this.state.errors['pautorizzata_telefono']===true}>
                                            <InputLabel
                                                shrink={true}
                                                FormControlClasses={{
                                                    root: this.state.errors['pautorizzata_telefono']!==true ? classes.inputLabelFocused : '',
                                                    focused: this.state.errors['pautorizzata_telefono']!==true ? classes.inputLabelFocused : '',
                                                }}
                                                htmlFor="pautorizzata_telefono"
                                            >
                                                Telefono
                                            </InputLabel>
                                            <Input
                                                placeholder="Inserire numero di telefono"
                                                onChange={this.handlePAutChange}
                                                name="Telefono"
                                                value={this.state.weight_detail.Persona_Autorizzata.Telefono}
                                                classes={{
                                                    underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                                }}
                                                id="pautorizzata_telefono"
                                            />
                                            {this.state.errors['pautorizzata_telefono']===true && <FormHelperText id="name-error-text">Campo Telefono obbligatorio</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="error-message-container">
                                {associa_error_message}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseShippingDialog} color="primary">
                                Annulla
                            </Button>
                            <Button onClick={this.handleSaveShipping} color="primary">
                                Conferma
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <div className="filter-container">
                        <div className="filter-content">
                            <p className="picker-label"><span>Da</span></p>
                            <SingleDatePicker
                                date={this.state.da} // momentPropTypes.momentObj or null
                                onDateChange={(date) => {this.setState({ da:date }); this.updateWeightList({da:date.format('YYYY-MM-DD'),a:moment(this.state.a).add(1, 'days').format('YYYY-MM-DD'),tipoPesata:this.state.causale}); }} // PropTypes.func.isRequired
                                focused={this.state.focusedDa} // PropTypes.bool
                                onFocusChange={this.onFocusChange}
                                isOutsideRange ={() => false }
                            />
                            <p className="picker-label"><span>A</span></p>
                            <SingleDatePicker
                                date={this.state.a} // momentPropTypes.momentObj or null
                                onDateChange={(date) => {this.setState({ a:date }); this.updateWeightList({da:this.state.da.format('YYYY-MM-DD'),a:date.add(1, 'days').format('YYYY-MM-DD'),tipoPesata:this.state.causale}); }} // PropTypes.func.isRequired
                                focused={this.state.focusedA} // PropTypes.bool
                                onFocusChange={({ focused }) => this.setState({ focusedA:focused })} // PropTypes.func.isRequired
                                isOutsideRange ={() => false }
                            />
                            <p className="picker-label"><span>CAUSALE</span></p>
                            <FormControl >
                                <Select
                                    classes={{
                                        root: classes.inputRoot,
                                        selectMenu:classes.selectMenu
                                    }}
                                    autoWidth={true}
                                    value={this.state.causale}
                                    onChange={this.handleChange}
                                    displayEmpty
                                    inputProps={{
                                        name:"causale",
                                        placeholder: 'age-simple',
                                    }}
                                >   
                                    {itemscausali}
                                    
                                    
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="main-container grid-container">
                    <div className="action-button">
                        <Tooltip title="Associa pesata" placement="bottom">
                            <Button
                                color="primary"
                                variant="fab"
                                classes={{
                                    root: classes.buttonRoot,
                                }}
                                data-for='add'
                                disabled={!this.isButtonDisabled()}
                                mini={true}
                                onClick={this.handleOpenAggiungiPesataDialog}
                            >
                                <ContentAdd />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Dettaglio pesata" placement="bottom">
                            <Button
                                variant="fab"
                                color="primary"
                                data-tip=''
                                classes={{
                                    root: classes.buttonRoot,
                                }}
                                data-for='edit'
                                disabled={this.isButtonDisabled()}
                                mini={true}
                                onClick={this.handleDetailPesataClick}

                            >
                                <ContentDetail />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Shipping document" placement="bottom">
                            <Button
                                variant="fab"
                                color="primary"
                                classes={{
                                    root: classes.buttonRoot,
                                }}
                                data-tip=''
                                data-for='clone'
                                disabled={this.isButtonShippingDisabled()}
                                mini={true}
                                onClick={this.handleOpenShipping}
                            >
                                <ContentBoat />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Stampa shipping document" placement="bottom">
                            <Button
                                variant="fab"
                                color="primary"
                                classes={{
                                    root: classes.buttonRoot,
                                }}
                                data-tip=''
                                data-for='print-ingredient'
                                disabled={this.isButtonPrintDisabled()}
                                mini={true}
                                onClick={this.handlePrintClick}
                            >
                                <ContentPrintLabel />
                            </Button>
                        </Tooltip>
                    </div>
                    <ReactDataGrid
                            onGridSort={this.handleGridSort}
                            columns={columns}
                            rowGetter={this.rowGetter}
                            rowsCount={this.state.rows!==null ? this.state.rows.length : this.props.weight_list.length}
                            minHeight={320}
                            onRowUpdated={this.handleRowUpdated}
                            rowSelection={{
                                    showCheckbox: true,
                                    enableShiftSelect: false,
                                    onRowsSelected: this.onRowsSelected,
                                    onRowsDeselected: this.onRowsDeselected,
                                    selectBy: {
                                        indexes: this.state.selectedIndexes
                                    }
                            }}
                            onRowClick={this.onRowClick}
                    />
                
                    </div>
                </Main>
            </div>
        )
    }
}

Dashboard.propTypes = {
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    history: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    const { sidemenu,weight } = state
    const {isFetching,weight_list,causali,associapesata_dialog_open,associa_error_message,dettagliopesata_dialog_open,shipping_dialog_open,messaggio_dialog_open,pesata_error_message,weight_detail} = weight
    const { expanded,selected } = sidemenu
    return {
        isFetching,
        expanded,
        selected,
        weight_list,
        causali,
        associapesata_dialog_open,
        associa_error_message,
        dettagliopesata_dialog_open,
        shipping_dialog_open,
        messaggio_dialog_open,
        pesata_error_message,
        weight_detail
    }
}

export default withStyles(stile)(connect(mapStateToProps)(Dashboard));

/*
<NavItem eventKey="settings">
<NavIcon>
<i className="icon-address" style={{ fontSize: '1.5em', verticalAlign: 'middle' }} />
</NavIcon>
<NavText style={{ paddingRight: 32 }}>
Gestione Trasferte
</NavText>
<NavItem eventKey="settings/policy">
<NavText>
Policy
</NavText>
</NavItem>
<NavItem eventKey="settings/network">
<NavText>
Network
</NavText>
</NavItem>
</NavItem>
*/