import { EDIT_USER_REQUEST, EDIT_USER_SUCCESS, EDIT_USER_FAILURE } from '../actions';
import { CLOSE_EDIT_USER_DIALOG } from '../actions';
import { CAMBIA_PASSWORD_REQUEST, CAMBIA_PASSWORD_SUCCESS, CAMBIA_PASSWORD_FAILURE } from '../actions';
import { OPEN_PASSWORD_DIALOG,CLOSE_PASSWORD_DIALOG } from '../actions';

// The userdata reducer
export default function loggeduser(state = {
	isFetching: false,
    password_dialog_open: false,
    error_message:'',
    authenticated: false,
    editmessage_dialog_open:false
	}, action) {
	switch (action.type) {
		case EDIT_USER_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			})
		case EDIT_USER_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
                error_message: '',
                editmessage_dialog_open:true,
				authenticated: action.authenticated || false
			})
		case EDIT_USER_FAILURE:
			return Object.assign({}, state, {
                isFetching: false,
                editmessage_dialog_open:true,
                error_message:action.error,
            })
        case CAMBIA_PASSWORD_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			})
		case CAMBIA_PASSWORD_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
                error_message: '',
                password_dialog_open:false,
                editmessage_dialog_open:true,
				authenticated: action.authenticated || false
			})
        case CAMBIA_PASSWORD_FAILURE:
			return Object.assign({}, state, {
                isFetching: false,
                editmessage_dialog_open:true,
                password_dialog_open:false,
                error_message:action.error,
            })
        case CLOSE_EDIT_USER_DIALOG:
            return Object.assign({}, state, {
                editmessage_dialog_open:false
            })
        case OPEN_PASSWORD_DIALOG:
            return Object.assign({}, state, {
                password_dialog_open:true
            })
        case CLOSE_PASSWORD_DIALOG:
            return Object.assign({}, state, {
                error_message:'',
                password_dialog_open:false
            })
		default:
			return state
	}
}