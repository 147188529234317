// components/Login.js

import React, { Component } from 'react'
import PropTypes from 'prop-types';
import LockIcon from 'material-ui-icons/Lock';
import TextField from 'material-ui/TextField';
import Button from 'material-ui/Button';
import Paper from 'material-ui/Paper';
import styles from '../css/app.css'
import logo from '../img/logo.png'
import logoAnt from '../img/logoAnt.jpg'
import styled from 'styled-components';
import { FormControl,FormHelperText  } from 'material-ui/Form';
import Input, { InputLabel } from 'material-ui/Input';
import CustomSideNav from './CustomSideNav'
import { connect } from 'react-redux'
import purple from 'material-ui/colors/purple';
import { withStyles } from 'material-ui/styles';
import Grid from 'material-ui/Grid';
import { registerUser,closeMessaggioRegistrazione }  from '../actions/userAction';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from 'material-ui/Dialog';

const Main = styled.main`
		position: relative;
		overflow: hidden;
		transition: all .15s;
		padding: 0 0px;
        z-index: 1007;
        display:block;
		margin-left: ${props => (props.expanded ? 230 : 55)}px;
	`;

    const stile = theme => ({
        container: {
          display: 'flex',
          flexWrap: 'wrap',
        },
        formControl: {
          margin: theme.spacing.unit,
          width:250
        },
        inputLabelFocused: {
          color: 'rgb(0, 121, 191)',
        },
        inputInkbar: {
          '&:after': {
            backgroundColor: 'rgb(0, 121, 191)',
          },
        },
        textFieldRoot: {
          padding: 0,
          'label + &': {
            marginTop: theme.spacing.unit * 3,
          },
        },
        textFieldInput: {
          borderRadius: 4,
          backgroundColor: theme.palette.common.white,
          border: '1px solid #ced4da',
          fontSize: 16,
          padding: '10px 12px',
          width: 'calc(100% - 24px)',
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
          },
        },
        textFieldFormLabel: {
          fontSize: 18,
        },
        button: {
            margin: theme.spacing.unit,
          },
      });

	
class Registrazione extends Component {
   
	constructor(props) {
        super(props);
        this.state = {
            user: {},
            errors:{},
            errorsText:{}
        };
        this.handleChange = this.handleChange.bind(this);
	}

	componentWillUnmount() {
		this.setState({open: false});
	}

    onSaveClick(){
        if(this.validation(this.state.user)===true){
            console.log('aaa');
            this.props.dispatch(registerUser(this.state.user));
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        var user = this.state.user;
        var val = value;
        if(name=="Piva")
          val=value.toUpperCase();
        user[name]=val;
        this.setState({ user: user });
    }

    handleCloseMessaggioDialog = () => {
        this.props.dispatch(closeMessaggioRegistrazione());
        if(this.props.errorMessage.length===0){
            this.props.history.push("/");
        }
    };

    validation(user) {
        var errors={};
        var errorsText={};
		var retVal=true;
        console.log(user);
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;   

		if(!user.Nome || user.Nome.length===0){
			errors['nome']= true ;
			retVal=false;
		}
        if(!user.Cognome || user.Cognome.length===0){
			errors['cognome']= true;
			retVal=false;
        }
        if(!user.Email || user.Email.length===0){
			errors['email']= true;
			retVal=false;
        }
        if(user.Email && user.Email.length>=0 && !re.test(user.Email)){
			errors['email']= true;
			retVal=false;
		}
        if(!user.Cellulare || user.Cellulare.length===0){
			errors['cellulare']= true;
			retVal=false;
        }
        if(!user.UserName || user.UserName.length===0){
			errors['username'] = true;
			retVal=false;
        }
        if(!user.Password || user.Password.length===0){
            errors['password'] = true;
            errorsText['password']="Campo Password obbligatorio";
			retVal=false;
        }
        else{
            errors['password'] = false;
        }
        if(!user.ConfermaPassword || user.ConfermaPassword.length===0){
            errors['confermapassword']= true;
            errorsText['confermapassword']="Campo Conferma Password obbligatorio";
			retVal=false;
        }
        else{
            errors['confermapassword']= false;
        }
        if((errors['password'] == false && errors['confermapassword'] == false)&&( user.Password!=user.ConfermaPassword)){
            errors['confermapassword']= true;
            errors['password'] = true;
            errorsText['password']="Password e Conferma devono coincidere";
            errorsText['confermapassword']="Password e Conferma devono coincidere";
        }
        if(!user.RagioneSociale || user.RagioneSociale.length===0){
			errors['ragionesociale']= true;
			retVal=false;
        }

        var reIva = /^(IT){0,1}[0-9]{11}$/;
        
        if(!user.Piva || user.Piva.length===0){
			errors['piva']= true;
			retVal=false;
        }
        if(user.Piva && user.Piva.length>=0 && !reIva.test(user.Piva)){
			errors['piva']= true;
			retVal=false;
		}
        if(!user.Telefono || user.Telefono.length===0){
			errors['telefono']= true;
			retVal=false;
        }
        if(!user.PEC || user.PEC.length===0){
			errors['pec']= true;
			retVal=false;
        }
        if(user.PEC && user.PEC.length>=0 && !re.test(user.PEC)){
			errors['pec']= true;
			retVal=false;
		}
        if(!user.Indirizzo || user.Indirizzo.length===0){
			errors['indirizzo']= true;
			retVal=false;
        }
        if(!user.Provincia || user.Provincia.length===0){
			errors['provincia']= true;
			retVal=false;
        }
        if(user.Provincia && user.Provincia.length>=3){
			errors['provincia']= true;
			retVal=false;
		}
        if(!user.CAP || user.CAP.length===0){
			errors['cap']= true;
			retVal=false;
        }
        if(!user.Localita || user.Localita.length===0){
			errors['localita']= true;
			retVal=false;
        }
        if(!user.Paese || user.Paese.length===0){
			errors['paese']= true;
			retVal=false;
        }
		this.setState({errors: errors,errorsText:errorsText});
		return retVal;
	}

	render() {
        const { classes } = this.props;
        const { dispatch,history,messageOpen,errorMessage,isFetching } = this.props;
		return (
			<div className="loggedout">
                {
					this.props.isFetching &&
					<div className="fullBG">
						<div className="loader">
							<div></div>
						</div>
					</div>
				}
                <div className="header">
                    <Grid container>
                        <Grid item xs={12} sm={6} className='logo'>
                            <img src={logo} alt="ISLogo" height="110" width="260" style={{marginRight:35}} />
                            
                            <img src={logoAnt} alt="ANTLogo" height="110" width="230"/>
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>
                            <div className="site-title">
                                <div>
                                    <p>PESA PUBBLICA</p>
                                    <p>DI NAZZANO</p>
                                </div>
                            </div>
                            
                        </Grid>
                    </Grid>
                </div>
				<div className='registrazione-container'>
             
					<div>   
                    <Dialog
                        open={messageOpen}
                        onClose={this.handleCloseMessaggioDialog}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            Registrazione
                        </DialogTitle>
                        <DialogContent>
                            {errorMessage.length>0&&
                                <DialogContentText>
                                    {errorMessage}
                                </DialogContentText>
                            }
                            {errorMessage.length==0&&
                                <DialogContentText>
                                    <p>Utente registrato correttamente.</p>
                                    <p>Controlla la casella email per confermare l'indirizzo inserito in fase di registrazione</p>
                                </DialogContentText>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseMessaggioDialog} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
							<Paper className='registrazione'>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <h2>Dati personali</h2>
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['nome']===true} >
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="nome"
                                        >
                                            Nome
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire il nome"
                                            onChange={this.handleChange}
                                            name="Nome"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="nome"
                                        />
                                        {this.state.errors['nome']===true && <FormHelperText id="name-error-text">Campo Nome obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['cognome']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="cognome"
                                        >
                                            Cognome
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire il cognome"
                                            onChange={this.handleChange}
                                            name="Cognome"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="cognome"
                                        />
                                        {this.state.errors['cognome']===true && <FormHelperText id="name-error-text">Campo Cognome obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            

                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['email']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="email"
                                        >
                                            Email
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la mail"
                                            onChange={this.handleChange}
                                            name="Email"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="email"
                                        />
                                        {this.state.errors['email']===true && <FormHelperText id="name-error-text">Campo Email obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['cellulare']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="cellulare"
                                        >
                                            Cellulare
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire il cellulare"
                                            onChange={this.handleChange}
                                            name="Cellulare"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="cellulare"
                                        />
                                        {this.state.errors['nome']===true && <FormHelperText id="name-error-text">Campo Cellulare obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['username']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="username"
                                        >
                                            Username
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire lo username"
                                            onChange={this.handleChange}
                                            name="UserName"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="username"
                                        />
                                        {this.state.errors['username']===true && <FormHelperText id="name-error-text">Campo Username obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            </Grid>

                                    
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['password']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="password"
                                        >
                                            Password
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la password"
                                            type="password"
                                            onChange={this.handleChange}
                                            name="Password"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="password"
                                        />
                                        {this.state.errors['password']===true && <FormHelperText id="name-error-text">{this.state.errorsText['password']}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['confermapassword']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="confermapassword"
                                        >
                                            Conferma password
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la password"
                                            type="password"
                                            onChange={this.handleChange}
                                            name="ConfermaPassword"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="confermapassword"
                                        />
                                        {this.state.errors['confermapassword']===true && <FormHelperText id="name-error-text">{this.state.errorsText['confermapassword']}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            </Grid>


                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <h2>Dati aziendali</h2>
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['ragionesociale']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="ragionesociale"
                                        >
                                            Ragione Sociale
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la ragione sociale"
                                            onChange={this.handleChange}
                                            name="RagioneSociale"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="ragionesociale"
                                        />
                                        {this.state.errors['ragionesociale']===true && <FormHelperText id="name-error-text">Campo Ragione Sociale obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['piva']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="piva"
                                        >
                                            Partita IVA
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la partita IVA"
                                            onChange={this.handleChange}
                                            name="Piva"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="piva"
                                        />
                                        {this.state.errors['piva']===true && <FormHelperText id="name-error-text">Campo Partita IVA obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['telefono']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="telefono"
                                        >
                                            Telefono
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire il telefono"
                                            onChange={this.handleChange}
                                            name="Telefono"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="telefono"
                                        />
                                        {this.state.errors['telefono']===true && <FormHelperText id="name-error-text">Campo Telefono obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['pec']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="pec"
                                        >
                                            PEC
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la PEC"
                                            onChange={this.handleChange}
                                            name="PEC"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="pec"
                                        />
                                        {this.state.errors['pec']===true && <FormHelperText id="name-error-text">Campo PEC obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['indirizzo']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="indirizzo"
                                        >
                                            Indirizzo
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire l'indirizzo"
                                            onChange={this.handleChange}
                                            name="Indirizzo"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="indirizzo"
                                        />
                                        {this.state.errors['indirizzo']===true && <FormHelperText id="name-error-text">Campo Indirizzo obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['provincia']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="provincia"
                                        >
                                            Provincia
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la provincia"
                                            onChange={this.handleChange}
                                            name="Provincia"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="provincia"
                                        />
                                        {this.state.errors['provincia']===true && <FormHelperText id="name-error-text">Campo Provincia obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['cap']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="cap"
                                        >
                                            CAP
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire il CAP"
                                            onChange={this.handleChange}
                                            name="CAP"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="cap"
                                        />
                                        {this.state.errors['cap']===true && <FormHelperText id="name-error-text">Campo CAP obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['localita']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="località"
                                        >
                                            Località
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la località"
                                            onChange={this.handleChange}
                                            name="Localita"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="località"
                                        />
                                        {this.state.errors['localita']===true && <FormHelperText id="name-error-text">Campo Località obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={24}>
                                <Grid item xs={12}  sm={6}>
                                    <FormControl className={classes.formControl} error={this.state.errors['paese']===true}>
                                        <InputLabel
                                            shrink={true}
                                            FormControlClasses={{
                                                root: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                                focused: this.state.errors['nome']!==true ? classes.inputLabelFocused : '',
                                            }}
                                            htmlFor="paese"
                                        >
                                            Nazione
                                        </InputLabel>
                                        <Input
                                            placeholder="Inserire la nazione"
                                            onChange={this.handleChange}
                                            name="Paese"
                                            classes={{
                                                underline: this.state.errors['nome']!==true ? classes.inputInkbar : '',
                                            }}
                                            id="paese"
                                        />
                                        {this.state.errors['paese']===true && <FormHelperText id="name-error-text">Campo Nazione obbligatorio</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <div className="button-container">
                                <Button className={classes.button} variant="raised" color="primary" onClick={() => {this.onSaveClick()}}>
                                    Conferma
                                </Button>
                                <Button className={classes.button} variant="raised" color="primary" onClick={() => {this.props.history.push("/");}}>
                                    Annulla
                                </Button>
                            </div>
						</Paper>
					</div>
				</div>
			</div>
		)
	}
}

Registrazione.propTypes = {
	dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
	const { registrazione } = state
	const { messageOpen,errorMessage,isFetching } = registrazione
	return {
		messageOpen,
        errorMessage,
        isFetching
	}
}

export default withStyles(stile)(connect(mapStateToProps)(Registrazione));