// The middleware to call the API for quotes
import { CALL_API } from '../middleware/api'
import { EDIT_USER_REQUEST, EDIT_USER_SUCCESS, EDIT_USER_FAILURE } from '../actions';
import { CLOSE_EDIT_USER_DIALOG } from '../actions';
import { CAMBIA_PASSWORD_REQUEST, CAMBIA_PASSWORD_SUCCESS, CAMBIA_PASSWORD_FAILURE } from '../actions';
import { OPEN_PASSWORD_DIALOG,CLOSE_PASSWORD_DIALOG } from '../actions';

const serialize = function (filter){
    var str = [];
    for(var p in filter)
        if (filter.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(filter[p]));
        }
    return str.join("&");
}

export function salvaUtente(user) {
    return {
        [CALL_API]: {
            endpoint: 'user',
            authenticated: true,
            method: 'POST',
            data:user,
            types: [EDIT_USER_REQUEST, EDIT_USER_SUCCESS, EDIT_USER_FAILURE]
        }
    }
}

export function cambiaPassword(pwd) {
    return {
        [CALL_API]: {
            endpoint: 'user/CambiaPassword',
            authenticated: true,
            method: 'POST',
            data:{
                OldPassword:pwd.OldPassword,
                NewPassword:pwd.NewPassword
            },
            types: [CAMBIA_PASSWORD_REQUEST, CAMBIA_PASSWORD_SUCCESS, CAMBIA_PASSWORD_FAILURE]
        }
    }
}

export function openPasswordDialog() {
	return{
		type: OPEN_PASSWORD_DIALOG,
		authenticated: true
	}
}

export function closePasswordDialog() {
	return{
		type: CLOSE_PASSWORD_DIALOG,
		authenticated: true
	}
}

export function closeEditDialog() {
	return{
		type: CLOSE_EDIT_USER_DIALOG,
		authenticated: true
	}
}